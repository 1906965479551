<template>
  <footer class="footer" id="footer">
    <div class="footer__container">
      <div class="footer__logos-container">
        <a href="#">
        <img src="./../../assets/images/footer/logo-raptz-white.png" class="footer__raptz-logo">
        </a>
        <div class="footer__social-container">
          <a href="https://www.mixcloud.com/Radio_RapTz/">
            <img src="./../../assets/images/footer/logo-mixcloud.png" class="footer__social-logo">
          </a>
          <a href="https://soundcloud.com/user-745598996">              
            <img src="./../../assets/images/footer/logo-soundcloud.png" class="footer__social-logo">
          </a>
          <a href="https://www.instagram.com/radio.raptz/">  
            <img src="./../../assets/images/footer/logo-instagram.png" class="footer__social-logo">
          </a>   
          <a href="https://www.facebook.com/RadioRapTz/"> 
            <img src="./../../assets/images/footer/logo-facebook.png" class="footer__social-logo">
          </a>
          <a href="https://twitter.com/radio_raptz?lang=fr">  
            <img src="./../../assets/images/footer/logo-twitter.png" class="footer__social-logo">
          </a>  
        </div>
        <p class="footer__legal">Copyright © 2018 Raptz.com  Tous droits réservés</p>
      </div>
      <div class="footer__navigation">
        <ul>
          <li class="footer__navigation-el"><router-link class="footer__nav-text" v-bind:to="'/podcasts'">PODCASTS</router-link></li>
          <li class="footer__navigation-el"><router-link class="footer__nav-text" v-bind:to="'/shows'">SHOWS</router-link></li>
          <li class="footer__navigation-el"><router-link class="footer__nav-text" v-bind:to="'/prog'">PROG</router-link></li>
          <li class="footer__navigation-el"><router-link class="footer__nav-text" v-bind:to="'/actions'">ACTIONS</router-link></li>
          <li class="footer__navigation-el"><router-link class="footer__nav-text" v-bind:to="'/raptz'">RAPTZ</router-link></li>
          <li class="footer__navigation-el"><a class="footer__nav-text" href="https://linktr.ee/radio.raptz">CONTACTEZ-NOUS</a></li>
        </ul>
      </div>
     
    </div>
  </footer>
  
</template>

<script>
export default {
  name:"Footer",
  data() {
    return {
      errors : []
    }
  },
  methods: {
    checkForm: function(e) {
      console.log(e);
    }
  }
}
</script>

<style>

  .footer {
    background-color:#3A4D6C;
    width:100%;
    font-style: normal;
    font-weight: normal;
  }

  .footer__container {
    display:flex;
    flex-direction:row;
    align-items: flex-start;
    padding:4em 2em;
  }

  .footer__logos-container {
    flex:2;
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
  }

  .footer__raptz-logo {
    width:17em;
    margin-right:auto;
  }

  .footer__social-container {
    margin-top:3.7em;
    float:left;

  }

  .footer__social-logo {
    width:4.2em;
    margin-right:1.5em;
    margin-top:1em;
  }

  .footer__legal {
    margin-top:3.7em;
    font-family: Noto Sans;
    font-size: 1.3em;
    text-align: left;
    color: #FFFFFF;
  }

  .footer__navigation {
    flex:1;
  }

  .footer__navigation-el {
    list-style-type: none;
    font-family: Chau Philomene One;
    font-size: 1.9em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom:0.6em;
  }

  .footer__nav-text {
    color: #FFFFFF;  
  }

@media screen and (min-width: 96em ) {

  .footer__container {
    display:flex;
    flex-direction:row;
    align-items: flex-start;
    padding:8em 13em;
  }

}
</style>
