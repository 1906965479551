<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero"/>
     <div class="FilterTags__filters-container">
        <a href="#" v-on:click.prevent="showStylesDropDown=!showStylesDropDown">
          <div class="FilterTags__selector-container">
              <h4 class="FilterTags__selector">Styles musicaux</h4>
              <img v-if="showStylesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
              <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
          </div>
        </a>
      </div>
      <FilterTags @listOfStyle="handleStyles" class="FilterTags__filter" :filterList="styles" :showItems="showStylesDropDown" filterName="styles" />
      <div class="prog__days-options">
        <input class="invisible" type="radio" id="one" value="1" v-model="selectedDay">
        <label class="color-red  first-left" for="one">LUN</label>
        <input class="invisible" type="radio" id="two" value="2" v-model="selectedDay">
        <label class="color-red" for="two">MAR</label>
        <input class="invisible" type="radio" id="three" value="3" v-model="selectedDay">
        <label class="color-red" for="three">MER</label>
        <input class="invisible" type="radio" id="four" value="4" v-model="selectedDay">
        <label class="color-red" for="four">JEU</label>
        <input class="invisible" type="radio" id="five" value="5" v-model="selectedDay">
        <label class="color-red" for="five">VEN</label>
        <input class="invisible" type="radio" id="six" value="6" v-model="selectedDay">
        <label class="color-red" for="six">SAM</label>
        <input class="invisible" type="radio" id="seven" value="7" v-model="selectedDay">
        <label class="color-red" for="seven">DIM</label>
      </div>
      <div class="prog__list">
        <div v-if="plannings">
          <div class="shaped__backgrounds">
            <ul>
              <transition-group name="fade" mode="out-in">
                <li class="prog__card" v-for="planning in selectedAll" :key="planning.id">
                  <router-link :to="{ name:'show-detail', params: {slug: planning.show.slug, show:planning.show}}">
                    <ProgrammeCard class="card-container" :item="planning" />    
                  </router-link>
                </li>
              </transition-group>
            </ul>
          </div>
        </div>
        <div v-else class="loading">Loading prog</div>
      </div>
      <div class="before-footer"></div>
    </div>

</template>

<script>
import api from '@/services/api';
import axios from 'axios';
import MainHero from '@/components/hero/MainHero';
import ProgrammeCard from '@/components/cards/ProgrammeCard';
import FilterTags from '@/components/sections/FilterTags';

export default {
  name: 'Programme',
  components: {
    MainHero,
    ProgrammeCard,
    FilterTags
  },
  data() {
    return {
      showStylesDropDown :false,
      plannings : null,
      jours : null,
      styles : null,
      selectedDay : "",
      selectedStyles : [],
      hero: {
        imageHD : "images/programme/hero-la-prog-v2.jpg",
        imageM : "images/programme/hero-la-prog-v2.jpg",
        imageLD : "images/programme/hero-la-prog--s.jpg",
        titre : "La Prog",
        filtre: "red"
      }
    }
  },
  methods : {
    fetchPlannings() {
      const requestedPlanning= api.get(`planning/`);
      const requestedStyles = api.get(`styles/filtrables`);

      return axios.all([requestedPlanning, requestedStyles])
        .then(axios.spread((...responses) => {
          this.plannings = responses[0].data['results'];
          this.styles = responses[1].data['results'];
        })).catch(errors => {
          console.log(errors)
        })
    },
    
    handleStyles:function(selectedItems) {
     this.selectedStyles = selectedItems ;
    },

  },
  computed: {
    selectedAll: function(){

      const SelectedDay = () => {
        let jourSelectionne = this.selectedDay;
        if(jourSelectionne == "" || this.plannings == null) {
          return this.plannings;
        } else {
          return this.plannings.filter(function (itemsday) {
            return itemsday.jour == jourSelectionne ;
          }, this);
        }
      };
      
      const SelectedPlanning = (planningDay) => {
        if(this.selectedStyles.length < 1) {
          return planningDay;
        } else {
          return planningDay.filter(function (planning) {
            let arrayOfStyle = []
            for(let i = 0 ; i < planning.show.musique_styles.length ; i++){
              arrayOfStyle.push(planning.show.musique_styles[i].nom);
            }
            return this.selectedStyles.some(el => arrayOfStyle.includes(el));
          }, this);
        }
      };
      return SelectedPlanning(SelectedDay());
    },

    getDay: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }
      return day => dictDay[day];
    }
  }, 
  created() {
    let today = new Date();
    if (today.getDay() == 0) {
      this.selectedDay = 7;
    } else {
      this.selectedDay = today.getDay();
    }
    this.fetchPlannings()
  }
};
</script>

<style scoped>
.content {
  font-family: Chau Philomene One;
}

.prog__days-options {
  max-width: 117em;
  margin:0em auto 0em auto;
  padding-left:0em;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
}

label.first-left {
  margin-left:1.5em;
}

label {
  float: left;
  padding:1em;
  border-radius:0px;
  font-size: 2.2em;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color:#76849C;
  margin-right:2em;
}

.prog__list {
  margin-top:5em;
}

.prog__card{
  display:flex;
  position:relative;
  align-items: top;
  padding:2;
  max-width: 857px;
  height: 22em;
  margin:2em auto;
  list-style: none;
}

.card-container {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding:1em;
}


input.invisible {
  display:none;
}

input.all-styles {
  display:none;
}

input:checked + label {
  background-image: url("./../assets/images/icone/icon-checked--red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  color:white;
}

input:checked + label::before {
  /*no style*/
}
</style>