<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero"/>
    <div class="description">Un média <b>participatif</b>, <b>collaboratif</b> et <b>informatif</b> de proximité, avec une programmation variée et éclectique H24</div>
    <HorizontalSection class="wrapper" v-bind:sectionContent="sectionContentTop"/>
    <HorizontalSection class="wrapper" v-bind:sectionContent="sectionContentMiddleTop"/>
    <HorizontalSection class="wrapper"  v-bind:sectionContent="sectionContentMiddle"/>
    <HorizontalSection class="wrapper" v-bind:sectionContent="sectionContentBottom"/>
    <div class="before-footer"></div>
  </div>
</template>

<style scoped>
  .content {
    color: #1A202A;
  }

  .description {
    max-width:100%;
    text-align: left;
    padding:0 2em;
    margin:2.5em;
    font-size: 2.6em;
  }

  .wrapper {
    max-width:100%;
    padding:2em;
    margin: 0 0 2.5em 0;
  }

@media screen and (min-width: 800px ) {

  .content {
    color: #1A202A;
  }

  .description {
    height:auto;
    margin:5em auto 5em auto;
    text-align: center;
    color: #1A202A;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 300;
  }

  .wrapper {
    max-width: 117em;
    margin:10em auto;
  }
}



</style>

<script>
import MainHero from '@/components/hero/MainHero';
import HorizontalSection from '@/components/sections/horizontalSection';

export default {
  name: 'Raptz',
  data() {
    return {
      shows : null,
      hero: {
        imageHD : "images/about/about-bg-hero-img--l.png",
        imageM : "images/about/about-bg-hero-img--m.png",
        imageLD : "images/about/about-bg-hero-img--m.png",
      },
      sectionContentTop : {
        titre :"LA FAMILLE",
        texte : `<p>Radio RapTz, <b>c'est une famille d'acteurs</b>, </p>`,
        image :"images/about/pres-beats-media.jpg",
      },
      sectionContentMiddleTop : {
        titre :"BEATS • MEDIA • CULTURE",
        texte : `<p>Radio RapTz, <b>"La Rapporteuz d'actions sociales et culturelles"</b>, webradio <b>associative et participative</b> 
        proposant une programmation variée et éclectique à toute heure de la journée et de la semaine. 
        Nos animateurs, DJs et producteurs sont tous et toutes bénévoles.
        Avec une rotation régulière de <b>plus de quatre-vingt émissions</b>, il y en a pour tous les goûts !</p><br>
        <p>Nous ne diffusons <b>aucune publicité</b> dans le but de garantir notre liberté de diffusion et d'expression. 
        <b>Nous construisons</b> nous-même notre programmation avec l'envie de vous offrir la plus grande variété musicale et 
        promouvoir les <b>initiatives citoyennes.</b></p>`,
        image :"images/about/pres-podcasts.png",
      },
      sectionContentMiddle : {
        titre :"NOS ACTIONS",
        texte : `<p>Sur du court ou du long terme, les <b>ateliers d'initiations radiophonique</b> proposés par Radio RapTz forment les participants à la pratique radiophonique et journalistique.</p><br> 
        <p><b>En questionnant la société</b>, ils participent à la construction de leur projet radio en approfondissant des sujets culturels et artistiques, et surtout le monde qui les entoure.</p> <br>
        <p>Nos ateliers varient et s'adaptent aux publics avec lesquels nous travaillons. 
        Grâce à son <b>studio mobile</b>, la radio est donc nomade et peut se rendre au sein de différentes structures et événements 
        (collèges, maisons de quartier, en entreprise, centres sociaux et culturels, festival, fêtes de quartier et autres animations de rue) pour partager, transmettre et émettre. </p><br>
        <p>Pour en savoir plus sur les différents formats d'ateliers proposées par la radio, prenez contact à raptzactions@gmail.com.</p>`,
        image :"images/about/pres-actions.jpg",
      },
      sectionContentBottom : {
        titre :"NOUS REJOINDRE ET CONTRIBUER",
        texte : `<p>Nous vous invitons à rejoindre la webradio Radio RapTz en tant que bénévole. 
        Nous favorisons l'accompagnement des membres de la radio dans la réalisation de projets radio, qu'il soit à caractères informatif, culturel et social. <b>Reporters, journalistes, curieux, créateurs et acteurs culturels, soyez les bienvenu.es !</b></p><br> 
        <p>N'hésitez pas à nous contacter pour plus d'informations, nous nous ferons un plaisir de vous rencontrer et de vous accueillir dans nos studios !</p>`,
        image :"images/about/pres-nous-rejoindre.jpg",
      },
    }
  },
  components: {
    MainHero,
    HorizontalSection,
  }
};

</script>
