<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero" />
    <div class="ShowPodcast__title-background">
      <h2 class="ShowPodcast__title">
        {{title}} - Tous les podcasts
      </h2>
    </div>
    <div class="podcasts__list">
      <div v-if="podcasts">
        <div class="shaped__backgrounds">
          <ul>
            <transition-group name="fade" mode="out-in">
              <li
                class="podcast__card"
                v-for="podcast in selectAllPodcasts"
                :key="podcast.id"
              >
                <PodcastCard class="card-container" :podcast="podcast" />
              </li>
            </transition-group>
          </ul>
        </div>
        <div class="pagination">
          <div v-if="previous" class="previous" v-on:click="getPreviousItems"><img src="./../assets/images/icone/icon-arrow--previous.svg"/></div>
          <div class="current">{{current_page}}</div>
          <div v-if="next" class="next" v-on:click="getNextItems"><img src="./../assets/images/icone/icon-arrow--next.svg"/></div>
        </div>
      </div>
      <div v-else>Podcasts loading</div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
//import axios from "axios";
import MainHero from "@/components/hero/MainHero";
import RoundPicture from "@/components/images/RoundPicture";
import PodcastCard from "@/components/cards/PodcastCard";
import {unslugString} from "@/services/utils";

/**
 * Cette 'view' est basé sur la liste de postcast
 * TODO: Trouver une manière de ne pas dupliquer la page
 */
export default {
  name: "ShowPodcasts",
  components: {
    MainHero,
    RoundPicture,
    PodcastCard,
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      previous: null,
      title: null,
      next: null,
      total_pages: null,
      current_page: null,
      podcasts: null,

      hero: {
        imageHD: "images/podcast/hero-podcasts--l.jpg",
        imageM: "images/podcast/hero-podcasts--l.jpg",
        imageLD: "images/podcast/hero-podcasts--s.jpg",
        titre: "Podcasts",
        filtre: "blue",
      },
    };
  },
  methods: {
    //TODO: Devrait être bougé vers un service de navigation
    getNextItems() {
      let relativeUrl = this.next.substring(
        this.next.indexOf("api/") + "api/".length
      );
      return api.get(relativeUrl).then((response) => {
        this.$store.commit("setFilteredPodcasts", response.data["results"]);
        this.podcasts = this.$store.getters.podcasts;
        this.previous = response.data["previous"];
        this.current_page = response.data["current_page"];
        this.next = response.data["next"];
      });
    },

    //TODO: Devrait être bougé vers un service de navigation
    getPreviousItems() {
      let relativeUrl = this.previous.substring(
        this.previous.indexOf("api/") + "api/".length
      );
      return api.get(relativeUrl).then((response) => {
        this.$store.commit("setFilteredPodcasts", response.data["results"]);
        this.podcasts = this.$store.getters.podcasts;
        this.previous = response.data["previous"];
        this.current_page = response.data["current_page"];
        this.next = response.data["next"];
      });
    },

    fetchPodcasts() {
      const url = 'show/' + this.slug + '/podcasts/all'; 
      api.get(url).then((response) => {
          this.$store.commit('setFilteredPodcasts', response.data['results']);
          this.previous = response.data['previous'];
          this.next = response.data['next'];
          this.total_pages = response.data['total_pages'];
          this.current_page = response.data['current_page'];
        });
      this.podcasts = this.$store.getters.podcasts ;
      return this.podcasts ;
    }
  },

  computed: {
    selectAllPodcasts: function () {
      return this.$store.getters.podcasts;
    }
  },

  created() {
    this.fetchPodcasts();
    this.title = unslugString(this.slug);
  },
  /**
   * TODO better destroy watcher
   */
  beforeDestroy() {},
};
</script>

<style scoped>

.ShowPodcast__title-background {
  margin: 0  0.5em;
  font-family: var(--typo-title);
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.ShowPodcast__title {
  height: 61px;
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 140%;

  /* or 63px */
  color: var(--main-blue-color);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  margin: 1em 0;
}

.podcasts__list {
  margin-top: 5em;
}

.podcast__card {
  display: flex;
  position: relative;
  align-items: top;
  padding: 0em 2em;
  max-width: 857px;
  min-width: 700px;
  height: 20em;
  margin: 0em auto 2em auto;
  padding: 0 auto 2em auto;
  border-bottom: 2px solid #dfe0e2;
  list-style: none;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  width: 100%;
}
</style>
