<template>
  <div class="content" >
    <div class="shaped__backgrounds">
      <div class="page" v-if="article">
        <ArticleTitle 
          v-bind:articleImage="article.image" v-bind:articleTitre="article.titre" v-bind:articleDate="article.date_publication"
            v-bind:articleAuteur="article.auteur"
          />
        <section class="article-introduction" v-if="article.introduction" v-html="article.introduction"></section>
        <section class="article-description" v-if="article.archive_description" v-html="article.archive_description"></section>  
        <section class="article-description" v-else v-html="article.description"></section>
        <div class="article__categories">
          <div class="article__categorie-item" v-for="categorie in article.categories" :key="categorie.id">{{categorie.nom}}</div>
        </div>
        <div class="article__categories">
          <div class="article__categorie-item" v-for="localite in article.localites" :key="localite.id">{{localite.nom}}</div>
        </div>
      </div> 
      <div v-else>Loading article....</div>
      <RelatedPodcasts v-if="podcasts.length>0" :items="podcasts" titre="Podcasts de l'article" 
          circleColor="beige"
          type="podcasts"
          backgroundColor="#233045" 
          texteColor="white"/> 
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import axios from 'axios';
import ArticleTitle from '@/components/sections/ArticleTitle';
import RelatedPodcasts from '@/components/sections/RelatedPodcasts.vue';

export default {
  name:"Article",
  props : {
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return{
      article: null,
      podcasts: []
    }
  },
  methods : {
    fetchArticlePodcasts() {
      let urlArticle = 'article/' + this.slug ;
      let urlRelatedPodcasts = urlArticle + '/podcasts';

      const requestedArticle = api.get(urlArticle);
      const requestedPodcasts = api.get(urlRelatedPodcasts);

      return axios.all([requestedArticle, requestedPodcasts])
        .then(axios.spread((...responses) => {
          this.article = responses[0].data;
          this.podcasts = responses[1].data['results'];
        })).catch(errors => {
          console.error(errors);
          return this.$router.push({
            name: 'error'
          });
        })
    } 
  },
  created() {
    this.fetchArticlePodcasts();
    //this.fetchRelatedPodcast();
  },
  components: {
    ArticleTitle,
    RelatedPodcasts
  }
}

</script>

<style scoped>
  .content {
    padding-top:19em;
    padding-bottom:10em;
    font-style: normal;
    font-weight: normal;
    position:relative;
  }

  .page {
    max-width:117em;
    margin:auto auto;
  }

  .article-description {
    margin : 3em auto 0 auto;
    max-width:75em;
  }

  .article-introduction {
    margin : 3em auto 0 auto;
    max-width:75em;
  }

  .article-introduction::first-letter {
    margin-top:1em;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9em;
  }

  .article-introduction >>> p {
    margin-top:1em;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9em;
  }

/** CONTENT PAGE **/

  .article-description >>> h2 {
    margin-top:1em;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5em;
    letter-spacing: 0.03em;
  }

   .article-description >>> h3 {
    margin-top:1em;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5em;
    letter-spacing: 0.03em;
  }


  .article-description >>> p {
    margin-top:1em;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9em;
  }

  
  .article-description >>> a {
    color:#993366;
    font-weight:bold;
  }
/** Bottom Page **/
  .article__categories {
    display:flex;
    justify-content: center;
    max-width:75em;
    margin:1em auto 1em auto;
  }

  .article__categorie-item {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 100%;
    /* or 17px */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
    margin:1em;

    /* blue-grey-light */

    color: #657388;
  }

@media screen and (max-width: 800px ) {
  .article-description >>> iframe {
    width:400px;
    height:400px;
  }
}

@media screen and (min-width: 800px ) {
  .article-description >>> iframe {
    width:600px;
    height:400px;
  }
}


</style>