<template>
  <div class="content header__top-padding" id="content">
    <div class="if-podcasts" v-if="podcasts"> 
      <div class="hero-container">
        <section class="hero-main hero">
          <div class="inner">
            <div class="inside-title">
              <h1>
                <svg width="640" height="177" viewBox="0 0 624 177" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="home-hero-text">
                  <path id="bg-shape" d="M389.979 4.41581C409.408 3.61841 451.176 1.8278 462.81 1.04423C474.445 0.260672 484.846 1.79682 488.593 2.66284L486.721 17.0354C521.342 29.1417 553.279 34.6917 581.046 39.5171C596.363 42.1789 610.411 44.6202 622.941 47.8199C625.248 48.4091 563.374 91.6122 527.441 110.82L389.306 117.946C371.406 127.936 344.529 138.304 319.237 148.061C293.977 157.805 270.299 166.939 258.726 174.477L179.493 176.621L149.554 151.877L26.4402 174.477L32.9402 168.477L0.44043 98.3203L12.2041 86.2092C20.7498 85.1452 62.5467 80.5243 108.141 75.4835C167.807 68.887 233.975 61.5716 240.645 60.5671C246.59 59.6718 252.559 57.8549 257.521 56.3444C262.38 54.8652 266.274 53.68 268.233 53.9423L268.516 68.2504C277.748 68.7175 286.447 69.2269 294.635 69.7764L389.979 4.41581Z" fill="white"/>
                  <g id="intro-text">
                    <text id="Beats" transform="matrix(0.996637 -0.0819442 0.108431 0.994104 41.9404 96.8036)" fill="#F42740" xml:space="preserve" style="white-space: pre" font-family="Chau Philomene One" font-size="48" font-style="italic" letter-spacing="0.04em"><tspan x="0" y="41.9689">BEATS</tspan></text>
                    <text id="media" transform="matrix(0.996637 -0.0819442 0.108431 0.994104 191.94 99.234)" fill="#F42740" xml:space="preserve" style="white-space: pre" font-family="Chau Philomene One" font-size="48" font-style="italic" letter-spacing="0.04em"><tspan x="0" y="44.3856">MEDIA</tspan></text>
                    <text id="CULTURE !" transform="matrix(0.996637 -0.0819442 0.108431 0.994104 332.94 57.2705)" fill="#F42740" xml:space="preserve" style="white-space: pre" font-family="Chau Philomene One" font-size="48" font-style="italic" letter-spacing="0.04em"><tspan x="0" y="36.484">CULTURE !</tspan></text>
                    </g>
                  </g>
                </svg>
              </h1>
            </div>
          </div>
        </section>
      </div>
      <Main class="main" v-bind:lastPodcast="podcasts[0]" 
        v-bind:mainCard="mainCard" 
        :mainCardType="mainCardType"
        :message="message"
        :cardBlue="secondCard"
        :cardOrange="thirdCard"/>
      <div class="road-show__container">
        <RoadShow class="road-show"  v-bind:roadshows="roadshows"/>
        <img class="road-show-separateur" src="./../assets/images/home/home-illu-under-roadshow@2x.png">
      </div>
      <LatestPodcasts class="podcasts" v-bind:styles="styles" v-bind:podcasts="podcasts"/>
      <About/>
      <Partenaires v-bind:partenaires="partners"/>
      </div>
    <div v-else>Loading....</div>
  </div>
</template>

<style>

.hero-main{
  background:url("./../assets/images/home/home-bg-hero-img-noText--l.jpg");
  background-size:cover;
  background-position:center top;
  background-attachment:fixed;
  height: 52em;
}

.hero-main::after {
    content:'';
    height: 9.8em;
    background: url("./../assets/images/home/scratch-light-hero--1900w.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    width: 100%;
    bottom: -1px;
    left: 0px;
    border:none;
}

.hero, .content {
  text-align:center; 
  position:relative;
  width: 100%;
}

.content {
  background-color: #FBF7F7;
}
.inner {
  height: 100%;
  position: relative;
}

.hero .inner{
  background: linear-gradient(10deg, rgba(18, 21, 23, 0.5) 0%,rgba(220, 234, 243, 0.3) 100%);
  background: -webkit-linear-gradient(10deg, rgba(18, 21, 23, 0.5) 0%,rgba(220, 234, 243, 0.3) 100%);
  background: -moz-linear-gradient(10deg, rgba(18, 21, 23, 0.5) 0%,rgba(220, 234, 243, 0.3) 100%);
}

.inside-title {
  position:absolute; 
  top: 22%; 
  width:100%;
}

.inside-title svg {
  max-width: 380px;
}


.inside {
    width:100%;
    background-color: #FBF7F7;
    position: relative;
    padding-top: 1em;
    margin-top: -1.5em;
}

.hero-container {
  position:relative;
}

.road-show__container{
  position:relative;
}

.road-show-separateur {
  position:absolute;
  right:0;
  max-width:80vw;
}

@media screen and (min-width: 1270px ) {
  .inside-title svg {
      max-width: 580px;
  }
}


</style>

<script>
import MainHero from '@/components/hero/MainHero';
import Main from '@/components/sections/Main';
import RoadShow from '@/components/sections/RoadShow';
import LatestPodcasts from '@/components/sections/LatestPodcasts';
import About from '@/components/sections/About';
import Partenaires from '@/components/sections/Partenaires';

import api from '@/services/api';
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      mainCardType: "",
      mainCard: null,
      secondCard: null,
      thirdCard: null,
      roadshows:null,
      podcasts:null,
      partners:null,
      //Need a class
      message:{
        titre:null,
        description:null,
        image:null,
        publication:false
      },
      styles:{},
      hero: {
        imageHD : "images/home/home-bg-hero-img--l.png",
        imageM : "images/home/home-bg-hero-img--l.png",
        imageLD : "images/home/home-bg-hero-img--l.png",
        titre : ""
      }
    }
  },
  methods : {
      fetchAllHome() {
      const requestedRoadshows= api.get(`roadshows/upcoming`);
      const requestedPocasts= api.get(`podcasts/latest/`);
      const requestedHome = api.get(`home/`);
      const requestedPartners = api.get(`home/partenaires`);
      const requestStyles = api.get(`styles/home`)

      const checkMisEnAvant = (items) => {
        if (items['article'] != null && items['roadshow'] == null && items['podcast'] == null && items['show'] == null) {
          this.mainCardType = 'article-detail';
          return items['article'];
        } else if (items['article'] == null && items['roadshow'] == null && items['podcast'] != null && items['show'] == null) {
          this.mainCardType = 'podcast-detail';
          return items['podcast'];
        } else {
          this.mainCardType = 'show-detail';
          return items['show'];
        }
      }
      /**
       * We just check if param one is null
       */
      const checkPodcastOrArticle = (item1, item2) =>{
        if (item1 == null && item2 != null){
          return item2
        }
        return item1
      }

      return axios.all([requestedRoadshows, requestedPocasts, requestedHome, requestStyles, requestedPartners])
        .then(axios.spread((...responses) => {
          this.roadshows = responses[0].data['results'];
          this.podcasts = responses[1].data['results'];
          this.mainCard = checkMisEnAvant(responses[2].data['results'][0]);
          this.message.description = responses[2].data['results'][0]['message'];
          this.message.image = responses[2].data['results'][0]['message_image'];
          this.message.titre = responses[2].data['results'][0]['message_titre'];
          this.message.publication = responses[2].data['results'][0]['message_publication'];
          this.message.lien = responses[2].data['results'][0]['message_lien'];
          this.styles = responses[3].data['results'];
          this.partners = responses[4].data['results'];
          this.secondCard = checkPodcastOrArticle(
            responses[2].data['results'][0]['article_secondaire'],
            responses[2].data['results'][0]['podcast_secondaire']);
          this.thirdCard = checkPodcastOrArticle(
            responses[2].data['results'][0]['article_tertiaire'],
            responses[2].data['results'][0]['podcast_tertiaire']);

        })).catch(errors => {
          console.log(errors);
        })
    }
  },
  created() {
    this.fetchAllHome()
  },

  components: {
    MainHero,
    Main,
    RoadShow,
    LatestPodcasts,
    About,
    Partenaires,
  }
};
</script>
