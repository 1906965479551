<template>
  <div class="card__container" :style="style">
    <div class="elements__container">
      <RoundPicture class="roundPicture" :picture="imageNom" :backgroundColor="'blue'" size="medium"/>
      <div class="LastPodcastCard__text-container">
        <div class="card__text">{{nomDeGroupe}}</div>
        <img class="icone" src='./../../assets/images/icone/icon-headphone--white.svg' />
      </div>
    </div>
  </div>
</template>

<style scoped>

  .card__container {
    width:100%;
    border-radius:1em;
    text-align:center;
    margin-bottom:auto;
  }

  .elements__container {
    position:relative;
    height:35em;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top:0;
    padding-left:0.5em;
    padding-right:0.5em;
  }

   .roundPicture {
    position:absolute;
    top:0px;
    width:22em;
    height:22em;
    transform:translate(0%,-45%);
  }


  .card__text {
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 2.8em;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-bottom:0.5em;
    padding: 5em 1em 1em 1em;
  }


  .icone {
    width:6.5em;
    height:auto;
    padding-bottom:4em;
  }

@media screen and (min-width: 800px ) {
  .card__container {
    max-height:31.5em;
    width:100%;
    height:auto;
    border-radius:1em;
    text-align:center;
    margin-bottom:auto;
  }

  .roundPicture {
    position:absolute;
    top:0px;
    width:18em;
    height:18em;
    transform:translate(0%,-45%);
  }

  .LastPodcastCard__text-container {
    position:relative;
    height:80%;
  }

  .card__text {
    font-size: 2.8em;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 4.2em 1em 1em 1em;
    }

}

</style>

<script>
import RoundPicture from '@/components/images/RoundPicture';

export default {
  name: 'LastPodcastCard',
  props:{
    color:String,
    imageNom:String,
    texte:String,
    nomDeGroupe:String
  },
  computed: {
    style(){
      return 'background-color:' + this.color;
    }
  },
  components: {
    RoundPicture
    }
}
</script>

