<template>
  <div class="search-wrapper">
    <input type="text" v-on:keyup.enter="submitSearchString" v-model="searchString" placeholder="articles, podcasts..."/>
    <button class="" v-on:click.prevent="submitSearchString">
      <img class="search-logo" src="./../../assets/images/icone/icon-search-black.svg">
    </button>
  </div>
</template>

<script>
export default {
  name:"Search",
  data() {
    return {
      searchString :"",
      }
    },
  methods: {
    submitSearchString() {
      if(this.$route.fullPath != '/search'){
        this.$store.commit('setSearchValue', this.searchString);
        return this.$router.push({
          name: 'search-results'
        });
      } else {
        this.$store.commit('setSearchValue', this.searchString);
      }

    }
  }
}
</script>

<style scoped>
  .search-wrapper {
      display:flex;
      max-width: 250px;
  }

  .search-logo {
    height:1em;
    width:3em;
  }

  input {
    padding: 4px 12px;
    color: rgba(0,0,0,.70);
    border: 1px solid rgba(0,0,0,.12);
    transition: .15s all ease-in-out;
    background: white;
    width: 150px;
  
  }
  input:focus {
    outline: none;
    transform: scale(1.05);

  }
  input::-webkit-input-placeholder {
    font-size: 12px;
    color: rgba(0,0,0,.50);
    font-weight: 100;
  }
</style>