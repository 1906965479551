<template>
  <div v-if="item" class="ProgrammationCard__container">
    <SquarePicture v-if="item.vignette" class="ArticleCard__square-picture" v-bind:picture="item.vignette" />
    <SquarePicture v-else-if="item.image" class="ArticleCard__square-picture" v-bind:picture="item.image" />
    <div class="ProgrammationCard__information">
        <h2 class="ProgrammationCard__titre">{{item.titre}}</h2>
        <div class="ProgrammationCard__auteur"> Par <span v-if="item.auteur" class="auteur">{{item.auteur}}</span>
        <span v-else class="auteur">Raptz</span></div>
        <div class="podcast__date">Publié le {{getDayOfWeek(item.date_publication)}} {{getDay(item.date_publication)}}</div> 
        <div class="ArticleCard__categories">
            <div v-if="item.categories">
                <div class="ArticleCard__categorie" v-for="categorie in item.categories" :key="categorie.id">
                    <div>{{categorie.nom}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import SquarePicture from '@/components/images/SquarePicture';

export default {
  name: 'ArticleCard',
  components : {
      SquarePicture
  },
  props: {
      item : Object,
  },
  computed: {
    getDayOfWeek: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }; 
      return (day) => {
          const date = new Date(day);
          return dictDay[date.getDay()]
        };
    },

    getDay: function(){
        const calendar = {
            '01':"Janvier",
            '02':"Février",
            '03':"Mars",
            '04':"Avril",
            '05':"Mai",
            '06':"Juin",
            '07':"Juillet",
            '08':"Août",
            '09':"Septembre",
            '10':"Octobre",
            '11':"Novembre",
        };
        return (articleDate) => {
            let month = calendar[articleDate.substring(5,7)];
            let day = articleDate.substring(8,10);
            let year = articleDate.substring(0,4);
            return day + " " + month + " " + year;
        };
    }
    }
}
</script>

<style scoped>

.ProgrammationCard__container {
  width: 82.7em;
  height: 22em;
  padding-bottom: 4em;
  color: black;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-family: Noto Sans;
}

.ArticleCard__square-picture {
  width:18em;
  height:18em;
  object-fit:cover;
}

.ProgrammationCard__information  {
  margin: 0 1.5em 0 1.5em;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width:50em;
}

h2.ProgrammationCard__titre {
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2em;
  color:#1F1F1F;
  text-align: left;
}

.ProgrammationCard__auteur {
  margin:0.5em 0 0.5em 0;
  font-size: 1.6em;
  text-align: left;
  color:#85878A;
}

.auteur {
  color:#1A202A;
  text-transform:uppercase;
}

.ArticleCard__categories {
  margin-top:1.2em;
  font-size: 1.2em;
  width:100%;
}

.ArticleCard__categorie {
  margin: 0 0.5em 0 0em;
  padding:0.5em;
  color: #657388;
  font-size: 1.4em;
  border-radius:10em;
  background-image: url("./../../assets/images/icone/item-background-grey.svg");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: contain;
  font-family: Noto Sans;
  float:left;
}

.ProgrammationCard__horaire {
  font-size:1.4em;
} 

@media screen and (min-width: 1270px ) {
  .ProgrammationCard__information {
    margin: 0 1.5em 0 1.5em;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width:58em;
  }
}
</style>