<template>
 <div class="player">
   <div class="player__main-container">
      <div class="player__container">
        <div class="player__texte-container">
          <div class="typo-title color__secondary uppercase ">on air</div>
          <div class="player__track-container">
            <img class="player__image" :src="this.$store.getters.currentProg['cover']">
            <div class="player__track-texte">
              <span class="player__track-artist" v-if="this.$store.getters.currentProg['artist']!=null">
                {{this.$store.getters.currentProg['artist']}}
                </span>
              <span v-if="this.$store.getters.currentProg['title']!=null">
                {{this.$store.getters.currentProg['title']}}
              </span>
            </div>
          </div>
        </div>
        <div class="player__button-container">
          <div class="player__play-stop" @click="clickPlay"  v-if="this.isPlaying==false">
            <img class="player__icone" src="./../../assets/images/player/player__icon-play.svg">
          </div>
          <div class="player__play-stop" @click="clickPause" v-else>
            <img class="player__icone" src="./../../assets/images/player/player__icon-pause.svg">
          </div>
        </div>
        <audio src="https://www.radioking.com/play/radio-raptz/78175" id='lecteur' preload="auto"></audio>
      </div>
    </div>
        <!-- 
          Le lecteur non HD (pour les versions mobiles?)
          <audio src="https://www.radioking.com/play/radio-raptz" id='lecteur' preload="auto"></audio>
        -->
  </div >
</template>

<style scoped>

  .player__main-container{
    position:relative;
    width:100%;
  }

  .player span {
    display: block;
  }

  .player__play-stop {
    position:absolute;
    right:0;
    transform: translate(-20%, 0%);
  }

  .player__icone {
    width:8.2em;
  }

  .player__container{
    position: fixed;
    bottom:0;
    width:100%;
    height:auto;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    background-image: url('./../../assets/images/player/player-bg--mobile.svg');
    background-size:100%;
    background-repeat: no-repeat;
    width:100vw;
    z-index:400;
    color: white;
  }

  .player__track-container {
    display:flex;
    justify-content: flex-end;
  }

  .player__texte-container{
    text-align: right;
    width:75%;
    margin-right:2em;
  }
  
  .player__button-container{
    width:25%;
  }

 .player__image {
    width:4em;
    height:4em;
    object-fit:cover;
    border-radius:2em;
    margin-right:0.5em;
  }

  .typo-title {
    padding-top:1em;
    margin-bottom:0.1em;
    font-size:1.6em;
    text-transform: uppercase;
  }
  
  .player__track-container {
    text-align:right;
  }

  .player__track-texte {
    font-size:1.4em;
  }

  .player__track-artist {
    font-weight:bold;
  }

  .player__track-texte span {
    margin:0;
    padding:0;
  }


@media screen and (min-width: 800px ) and (max-width: 1270px) {

  .player__texte-container{
    text-align: right;
    padding-top:2em;
    width:100%;
    margin-right:1em;
  }

    
  .player__button-container{
    width:22%;
  }

 .player__icone {
    width:8.2em;
  }
  
  .player__play-stop {
    position:absolute;
    right:0;
    bottom:0;
    transform: translate(-100%, 0%);
  }

 .player__image {
    width:6em;
    height:6em;
    object-fit:cover;
    border-radius:3em;
    margin-right:0.5em;
  }
}

@media screen and (min-width: 1270px ) {

  .player__image {
    width:4em;
    object-fit:cover;
    border-radius:2em;
    margin-right:1.5em;
  }

  .player__track-container {
    max-width:40em;
    display:flex;
    justify-content: flex-end;
  }


  .player__container{
    position: fixed;
    top:0em;
    right:4em;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    background-image: url('./../../assets/images/player/player-bg--desktop.svg');
    background-size:contain;
    background-repeat: no-repeat;
    z-index:200;
    width:50em;
    height:18em;
    color: white;
  }

 .player__icone {
    width:8.2em;
  }
  

  .player__play-stop {
    position:absolute;
    right:0;
    bottom:0;
    transform: translate(-50%, -70%);
  }
  
  .player__texte-container{
    text-align: right;
    margin-right:0.5em;
  }
  
  .player__button-container{
    width:25%;
  }

 .player__image {
    width:4em;
  }

  .typo-title {
    padding-top:1em;
    margin-bottom:0.1em;
    font-size:1.6em;
    text-transform: uppercase;
  }
  
  .player__track-texte {
    font-size:1.4em;
  }

  .player__track-artist {
    font-weight:bold;
  }

  .player__track-texte span {
    margin:0;
    padding:0;
  }
}



</style>

<script>
export default {
  name: 'Player',
  data:function(){
    return {
      isPlaying: false,
      currentShow: null
    }
  },
  methods: {
    clickPlay() {
      this.$emit('clickPlay');
      this.isPlaying = true;
 
    },

    clickPause(){
      this.$emit('clickPause');
      this.isPlaying = false;
    },

    fetchCurrentPlanning: function() {
      setInterval(() => {
          this.$store.dispatch('fetchCurrentProg')
        }, 60000)
    },
    
    getData: function(){
      this.$store.dispatch('fetchCurrentProg');
    }
  },
  created() {
    this.getData();
    this.fetchCurrentPlanning();
  }
};
</script>
