<template>
    <img class="card__square-image" :src="pictureURL">
</template>

<style scope>
  .card__square-image {
    width: 22em;
    height: 22em;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
  } 

</style>

<script>
export default {
  name:'SquarePicture',
  props: ['picture', 'podcast'],
  computed : {
    pictureURL(){
      if(this.picture.includes('http')){
        return this.picture;
      } else {
        return require(`@/assets/images/temp/${this.picture}`);
      }
    }
  }
}
</script>

