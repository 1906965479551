<template>
  <section v-bind:class="[sectionContainer, isRight ? rightImage : null]">
    <img class="section__image" v-bind:src="require(`@/assets/${sectionContent.image}`)" alt="Raptz image"/>
    <div class="section__text-container">
      <h3 class="section__titre">{{sectionContent.titre}}  </h3>
      <div class="section__texte" v-html="sectionContent.texte"></div> 
      <div v-if="sectionContent.titre=='BEATS • MEDIA • CULTURE'">
          <router-link class="ecouter" v-bind:to="'/podcasts'">
            <img class="icone" src='./../../assets/images/icone/icon-headphone--red.svg' />
            <div class="ecouter__texte"> Ecouter les podcasts </div>
          </router-link>
        </div>
      <div v-else-if="sectionContent.titre=='LA FAMILLE'">
        <a class="ecouter" :href="familyWorldMap">
          <img class="icone" src='./../../assets/images/icone/icon-array-up.svg' />
          <div class="ecouter__texte"> découvrir tous les membres de RAPTZ</div>
        </a>
      </div>
      <div v-else-if="sectionContent.titre=='NOS ACTIONS'">
        <router-link class="ecouter" v-bind:to="'/actions'">
          <img class="icone" src='./../../assets/images/icone/icon-array-up.svg' />
          <div class="ecouter__texte"> découvrir nos actions!</div>
        </router-link>
      </div>
      <div v-else>
        <div class="ecouter">
          <a class="horinzontal-align" :href="treeLink">
            <img class="icone" src='./../../assets/images/icone/icon-smile.svg' />
            <div class="ecouter__texte"> CONTACTEZ NOUS !</div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.section__container {
  display:flex;
  justify-content: space-between;
  flex-direction:column;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  color:#1A202A;
  padding:1em;
}

.section__image {
  width:100%;
  border-radius: 10px;
  object-fit:contain;
}

.section__text-container {
  margin:4.2em;
  text-align: left;
}

.section__titre {
  font-family: Chau Philomene One;
  font-size: 3.2em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  margin-bottom:33px;
  color:#233045;
}

.section__texte {
  font-family: Noto Sans;
  font-size: 1.6em;
  margin-bottom:1em;
  max-width: 50em;
}

.ecouter {
  margin-top:4em;
  text-align:center;
}

.horinzontal-align {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  text-align:center;
}

.ecouter__texte {
  margin-left:1em;
  font-size: 1.6em;
  line-height: 2.5em;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F42740;
  font-family: Chau Philomene One;
  }


@media screen and (min-width: 96em ) {
.section__container {
  display:flex;
  justify-content: space-between;
  flex-direction:row;
  align-items: center;
  max-width: 110em;
  font-style: normal;
  font-weight: normal;
  color:#1A202A;
  padding:1em;
}

.section__container-right-image {
  flex-direction:row-reverse;
}

.section__image {
  width:470px;
  border-radius: 10px;
  object-fit:contain;
}

.section__text-container {
  margin:4.2em;
  text-align: left;
}


.section__titre {
  font-family: Chau Philomene One;
  font-size: 3.4em;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom:33px;
  color:#233045;
}

.section__texte {
  font-family: Noto Sans;
  font-size: 1.6em;
  margin-bottom:1em;
}

.section__texte p {
  font-family: Noto Sans;
  font-size: 1.6em;
  margin-bottom:1em;

}

.ecouter {
  margin-top:2em;
  text-align:left;
  display:flex;
  flex-direction: row;
  align-items:center;
}

.icone {
  width:5em;
  height:auto;
}

.ecouter__texte {
  margin-left:1em;
  font-size: 1.8em;
  line-height: 2.5em;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F42740;
  font-family: Chau Philomene One;
  }
}


</style>
<script>
export default {
  name:'SectionHorizontale',
  data() {
      return {
        sectionContainer: 'section__container',
        rightImage: 'section__container-right-image',
        treeLink: "https://linktr.ee/radio.raptz",
        familyWorldMap : "https://umap.openstreetmap.fr/fr/map/contributeurs-internationaux-de-la-radio_861877#3/31.58/25.49"
      }
    },
  computed : {
    isRight() {
      if (this.sectionContent.titre=='NOS ACTIONS' || this.sectionContent.titre=="LA FAMILLE") {
          return  true ; 
      } else {
          return false ;
        }
    }
  },
  props : ['sectionContent']
}
</script>

