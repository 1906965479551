<template>
  <div class="SmallPodcastCard">
    <router-link class="" :to="{ name:'podcast-detail', params: {slug: podcast.slug}}">
      <div class="SmallPodcastCard__container">
        <div class="SmallPodcastCard__picture-container">
          <RoundPicture class="SmallPodcastCard__roundPicture" v-if="podcast.vignette" 
          :backgroundColor="circleColor" 
          :picture="podcast.vignette" 
          size="small"/>
          <RoundPicture class="SmallPodcastCard__roundPicture" v-else 
          :backgroundColor="circleColor"
          :picture="podcast.show.vignette" 
          size="small"  />
        </div>
        <div class="SmallPodcastCard__texte-container" :style="colorStyle">
          <div class="SmallPodcastCard__name">{{podcast.titre}}</div>
          <div v-if="podcast.numero != null" class="SmallPodcastCard__name"># {{podcast.numero}}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import RoundPicture from '@/components/images/RoundPicture';

export default {
    name: 'SmallPodcastCard',
    components: {
      RoundPicture
    },
    props : {
      podcast: Object,
      circleColor: String,
      backgroundColor:String,
      texteColor:String
    },
    computed: {
      colorStyle(){
        return 'color:' + this.texteColor + ';';
    },
  }
}
</script>

<style scope>
 .SmallPodcastCard {
    display:block;
    max-width:23.4em ;
    max-height:40em ;
  }

  .SmallPodcastCard__container { 
    display:flex !important;
    flex-direction: column;
    justify-content: flex-start;
    width:23.4em;
  }

  .SmallPodcastCard__picture-container {
    height:14em;
  }
  
  .SmallPodcastCard__roundPicture{
    max-width:14em;
    max-height:14em;
  }

  .SmallPodcastCard__texte-container {
    display:block;
    margin-top:3em;
    height:12em;
    max-width:100%;
  }

 .SmallPodcastCard__name {
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

 
 

</style>