<template>
  <div>
    <router-link
      :to="{ name: 'podcast-detail', params: { slug: podcast.slug } }"
    >
      <RoundPicture
        class="podcast__roundPicture"
        v-if="podcast.vignette"
        v-bind:picture="podcast.vignette"
        :backgroundColor="'gris'"
        size="small"
      />
      <RoundPicture
        class="podcast__roundPicture"
        v-else-if="podcast.show.vignette"
        v-bind:picture="podcast.show.vignette"
        :backgroundColor="'gris'"
        size="small"
      />
      <RoundPicture
        class="podcast__roundPicture"
        v-else
        v-bind:picture="podcast.show.image"
        :backgroundColor="'gris'"
      />
    </router-link>
    <div class="podcast__text-container">
      <router-link
        :to="{ name: 'podcast-detail', params: { slug: podcast.slug } }"
      >
        <h3 class="podcast__titre" v-if="podcast.titre">{{ podcast.titre }}</h3>
        <h3 class="podcast__titre" v-else>{{ podcast.show.nom }}</h3>
      </router-link>
      <div class="podcast__auteur">
        Par
        <span class="bold-noto-sans">{{getName()}}</span
        >
      </div>
      <div
        v-if="podcast.musique_styles != null"
        class="podcast__styles-container"
      >
        <div
          class="podcast__style"
          v-for="style in podcast.musique_styles"
          :key="style.nom"
        >      
          <div v-on:click.stop="setOneFilter(style.nom)">
            {{ style.nom }}
          </div>
        </div>
      </div>
      <div v-else class="podcast__styles-container">
        <div v-on:click.stop="setOneFilter()">
          <div
            class="podcast__style"
            v-for="style in podcast.show.musique_styles"
            :key="style.nom"
          >
            {{ style.nom }}
          </div>
        </div>
      </div>
      <div class="podcast__date-container">
        <img src="./../../assets/images/icone/icon-clock--grey.svg" />
        <div
          class="podcast__date"
          v-text="getStringDate(podcast.date_publication)"
        ></div>
        <div class="podcast__heure">{{ podcast.heure }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundPicture from "@/components/images/RoundPicture";
import {getPodcastName} from "@/services/utils";

export default {
  name: "PodcastCard",
  props: {
    podcast: Object,
  },
  data() {
    return {
      month: {
        "01": "Janvier",
        "02": "Février",
        "03": "Mars",
        "04": "Avril",
        "05": "Mai",
        "06": "Juin",
        "07": "Juillet",
        "08": "Août",
        "09": "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre",
      },
    };
  },
  methods: {
    setOneFilter(tag) {
      this.$store.commit('setOneFilter', {
        nom: 'podcasts',
        tag: tag
      });
    },
    /**
     * @description wrapper for utils function
     */
    getName(){
      return getPodcastName(this.podcast);
    }
  },
  computed: {
    getStringDate: function () {
      return (podcastDate) => {
        let month = this.month[podcastDate.substring(5, 7)];
        let day = podcastDate.substring(8, 10);
        return day + " " + month;
      };
    }

  },
  components: {
    RoundPicture,
  },
};
</script>
<style scoped>
.podcast__roundPicture {
  margin: 1em 0 0 0;
  width: 15em;
  height: 15em;
}

.podcast__text-container {
  margin-left: 3em;
  color: #1f1f1f;
  text-align: left;
  max-width: 30em;
}

.podcast__titre {
  font-size: 2.8em;
  color: #f42740;
  margin-bottom: 0.2em;
}

.podcast__styles-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1em;
}

.podcast__style {
  margin: 0 1em 0 0em;
  padding: 0.5em;
  color: #657388;
  font-size: 1.5em;
  border-radius: 10em;
  background-image: url("./../../assets/images/icone/item-background-grey.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: fill;
  font-family: Noto Sans;
}

.podcast__auteur {
  font-size: 1.5em;
  font-family: Noto Sans;
}

.podcast__date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 10%;
  font-family: Noto Sans;
}

.podcast__date {
  margin-right: 1em;
  margin-left: 1em;
  font-family: Noto Sans;
  font-size: 1.5em;
  color: #85878a;
}

.podast__heure {
  font-family: Noto Sans;
  font-size: 1.5em;
  color: #85878a;
}

@media screen and (min-width: 1270px) {
  .podcast__text-container {
    margin-left: 3em;
    color: #1f1f1f;
    text-align: left;
    max-width: 61em;
  }
}
</style>

