<template>
  <div id="app" >
    <SearchModal/>  
    <Header/>
    <Player @clickPlay="play" @clickPause="pause"/>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path"/>
    </transition>
    <Cookies/>
    <Footer />
  </div>
</template>

<script>
import Header from './components/header/Header';
import Player from './components/player/Player';
import Footer from './components/footer/Footer';
import Cookies from './components/cookies/Cookies';
import SearchModal from './components/header/SearchModal';

export default {
  name: 'App',
  components: {
    Header,
    Player,
    Footer,
    Cookies,
    SearchModal
  },
  data:function(){
    return {
      scrollPos:0,
    }
  },
  methods: {
    play() {
      document.getElementById('lecteur').play();
      //console.log("scrollPost" + this.scrollPos);
    },
    pause() {
      document.getElementById('lecteur').pause();
      //console.log("scrollPost" + this.scrollPos);
    }
  },
  mounted:function(){
    window.addEventListener('scroll', function(){
      //console.log("scrollPost" + this.scrollPos);
    })
  }
};
</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Chau+Philomene+One|Noto+Sans&display=swap');

:root{
  --main-blue-color:#3A4D6C;
  --typo-title:"Chau Philomene One";
}

* {
  margin:0;
  padding:0;
  border:none;
  box-sizing: border-box;
}

#app {
  margin:0;
  padding:0;
  width:100%;
}

body {
  font-family: "Noto Sans", sans-serif;
  width:100%;
  font-size: 62.5%;
  background-color:rgb(247, 246, 246);
  scroll-behavior: smooth;
  min-height: 100%;
}


h1, h2, h3, h4, h5 {
  font-family: var(--typo-title);
}

h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 3.5em;
    line-height: 140%;

    /* or 63px */
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

h2 {
  font-size:2.7em
}

h3 {
  font-size:1.7em;
  font-style: normal;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

.color__primary {
    color:#3A4D6C;
  }

.color__secondary {
  color: #F42740;
}

.color__white {
  color:white;
}

.typo-title {
  font-family: "Chau Philomene One", sans-serif;
  letter-spacing: .03em;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight:bold !important;
}

.margin-wrapper {
  max-width:117em;
  margin-left:auto;
  margin-right:auto;
}

.header__top-padding {
  padding-top:7em;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.before-footer {
  height:10em;
}

.loading {
  height:50vh;
}

/**SHAPED BACKGROUND FOR LIST PAGES */
.shaped__backgrounds {
  height:100%;
  width:100%;
  min-width:100vw;
  min-height:100vh;
  background-image: 
    url('./assets/images/deco-shapes/deco-shape-02.png'), 
    url('./assets/images/deco-shapes/deco-shape-01.png'),
    url('./assets/images/deco-shapes/deco-shape-right--02.png'),
    url('./assets/images/deco-shapes/deco-shape-03.png'),
    url('./assets/images/deco-shapes/deco-shape-02.png');
  background-position: 
    top 18em right , 
    left top 20em,
    top 60em right,
    top 140em right,
    bottom 0em left;
  background-size: 
    25%,
    22%,
    25%,
    25%,
    25%;
  background-repeat: 
    no-repeat, 
    no-repeat,
    no-repeat,
    no-repeat;
}

@media screen and (min-width: 1270px ) {
  h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 4.0em;
      line-height: 140%;

      /* or 63px */
      letter-spacing: 0.01em;
      text-transform: uppercase;
  }

  h2 {
    font-size:3.5em
  }

  h3 {
    font-size:2.2em;
    font-style: normal;
    font-weight: normal;
  }
}


/**MAIN SECTIONS **/
  .main__introduction {
    font-size: 15px;
    font-weight:normal;
  }


/****  global podcast and prog style ****/
.FilterTags__filters-container {
  max-width: 117em;
  margin:5em auto 2em auto;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
}

.FilterTags__selector-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 5em;
}

.FilterTags__selector {
  font-style: normal;
  font-weight: normal;
  font-size: 2em;
  text-transform: uppercase;
  margin-right:0.5em;
}

.FilterTags__filter {
  height:auto;
  width:auto;
}


/** LIST PAGINATION **/
.pagination {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width:200px;
  margin:1em auto 1em auto;
}

.previous {
  margin-right:2em;
  cursor: pointer;
}

.next {
  margin-left:2em;
  cursor: pointer;
}

.current {
  color:white;
  font-family:--typo-title;
  font-size:1.5em;
  width:3.5em;
  padding:1em;
  background-image: url("./assets/images/icone/icon-current-paginator.svg");
  background-repeat: no-repeat;
  background-position: center;
}
</style>
