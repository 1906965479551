<template>
  <div class="RelatedPodcasts" :style="style">
    <h2 class="RelatedPodcasts__titre">{{titre}} </h2>
    <div v-if="type=='podcasts'" class="RelatedPodcasts__liste">
      <SmallPodcastCard v-for="item in items" :key="item.id" 
      :podcast=item 
      :circleColor="circleColor"
      :texteColor="texteColor"/>
    </div>
    <div v-else>This is not a podcast</div>
  </div>
</template>

<script>
import RoundPicture from '@/components/images/RoundPicture';
import SmallPodcastCard from '@/components/cards/SmallPodcastCard';

/**
 * @items can be articles, shows or podcasts
 * @titre is a string use on top
 * @circleColor is passed to the RoundPicture components for background color
 */
export default {
  name: 'RelatedPodcasts',
  components: {
    RoundPicture,
    SmallPodcastCard
  },
  props: {
    items : Array,
    type : String,
    titre: String,
    circleColor: String,
    backgroundColor:String,
    texteColor:String
  },
  computed: {
    style(){
      return 'background-color:' + this.backgroundColor + ';' + 'color:' + this.texteColor + ';';
    },
  }
}
</script>

<style scoped>

.RelatedPodcasts {
  padding:2em;
}

.RelatedPodcasts__titre {
    font-family: Chau Philomene One;
    font-weight: normal;
    font-size: 3.2em;
    letter-spacing: 0.03em;
    margin:0 auto 2.5em 0;
    text-transform: uppercase;
}

.RelatedPodcasts__liste {
    max-width:117em;
    margin: 5em auto;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap:wrap;
}


</style>