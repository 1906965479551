<template>
  <div class="content">
    <h1 class="under-construction">Nos Partenaires</h1>
      <PartenaireCard v-for="partenaire in partenaires" v-bind:key="partenaire" v-bind:partenaire="partenaire" class="card-container"/>
    <div class="before-footer"></div>
  </div>
</template>

<style scoped>
  .content {
    color: #1A202A;
  }

  .under-construction {
    padding-top:10em;
  }

  .description {
    max-width:40em;
    height:auto;
    margin:2em auto;
    font-size: 2.7em;
    line-height: 145%;
    text-align: center;
    color: #1A202A;
  }

  .wrapper {
    max-width: 110em;
    margin:5em auto;
  }

  .before-footer {
    margin-top:10em;
    width:100%;
    height:41em;
    background: #C4C4C4;
  }

</style>

<script>
import MainHero from '@/components/hero/MainHero';
import PartenaireCard from '@/components/cards/PartenaireCard';
import api from '@/services/api' ;
//import axios from 'axios';

export default {
  name: 'Partenaires',
  data() {
    return {
      partenaires: [],

    }
  },
  methods: {
    getPartner:function() {
      return api.get('partenaires/')
      .then(response => {
        this.partenaires = response.data.results;
        
        console.log(response);
      })
    }

  },
  components: {
    MainHero,
    PartenaireCard
  },
  created () {
    this.getPartner();
  }

};

</script>
