<template>
  <section class="podcasts">
    <div class="titre__container margin-wrapper">
      <div class="titre__element">
        <img class="podcast__titre-background" src="./../../assets/images/icone/icon-headphone--red.svg">
          <router-link class="option no-color " to="/podcasts">
            <h2 class="titre">PODCASTS !</h2>
          </router-link>
      </div>
    </div>
    <div class="podcasts__categories margin-wrapper">
      <ul>
        <li class="podcasts__categorie" v-for="style in styles" :key="style.id">{{style.nom}}</li>
      </ul>
    </div>
    <div class="podcasts__list margin-wrapper">
      <SmallPodcastCard v-for="podcast in podcasts" :key="podcast.id" :podcast=podcast />
    </div>
    <router-link class="option no-color " to="/podcasts">
      <div class="podcast__all">
          <img class="icone" src='./../../assets/images/icone/icon-headphone-red-round.svg' /> 
          <div class="icone__texte"> Tous les podcasts </div>
      </div>
    </router-link>
  </section>
</template>

<script>
import RoundPicture from '@/components/images/RoundPicture';
import SmallPodcastCard from '@/components/cards/SmallPodcastCard';

  export default {
    name: 'LatestPodcasts',
    components: {
      RoundPicture,
      SmallPodcastCard
    },
    props: {
      styles : Array,
      podcasts : Array
    },
    data(){
      return {
        firstLinePodcast: [],
        secondLinePodcast : []
      }
    },
    methods:{
      separatePodcasts(){
        if(this.podcasts.length >= 10) {
          this.firstLinePodcast = this.podcasts.slice(0,5);
          this.secondLinePodcast = this.podcasts.slice(5,10);
        }
      }
    },
    created(){
      this.separatePodcasts();
    }
  }
</script>

<style scoped>

  .podcasts {
    background:url('./../../assets/images/home/home-illu-podcasts-bottomLeft--right@2x.png');
    background-size:30vw;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    padding : 0 4em;
  }

  .titre__container {
    margin: 10em auto 5em auto;
    height:18em;
  }

  .titre__element {
    position:relative;
    width:32em;
    height:12em;
    margin:auto;
  }

  .titre {
    position:absolute;
    bottom:0.5em;
    left:50%;
    font-family: Chau Philomene One;
    font-style: italic;
    font-weight: normal;
    font-size: 3.4em;
    text-transform: uppercase;
    transform: translate(-25%,0%) rotate(-11deg);
    color: black;
  }

  .podcast__titre-background {
    position:absolute;
    left:50%;
    top:0;
    transform:translateX(-100%);
    height:100%;
    width:auto;
  }

  .podcasts__categorie {
    display:inline;
    list-style: none;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    padding:0.6em;
    font-size: 2em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: white;
    background-image: url("./../../assets/images/icone/icon-checked--red.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
    margin-right:1em;
  }

  .podcasts__list {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap ;
    justify-content: center;
    margin-top:10em;
    margin-bottom:10em;
  }


  .podcast__all {
    width:30em;
    margin:0 auto;
    padding:1em;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:white;
    border: 3px solid #F42740;
    box-shadow: 2px 2px 0px #F42740;
    border-radius: 5px;
  }

  .icone {
    height:5em;
  }

  .icone__texte {  
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8em;
    text-transform: uppercase;
    color: #F42740;
    margin-left:1em;
  }

  .podcasts__bottom-separator {
    position:absolute;
    bottom:0;
  }

  @media screen and (min-width: 800px ) {
  .titre__container {
    margin: 25em auto 5em auto;
    width:100%;
    height:18em;
  }

  .titre__element {
      position:relative;
      width:50em;
      height:18em;
      margin:auto;
    }

  .titre {
    position:absolute;
    bottom:0.5em;
    right:-0.2em;
    font-family: Chau Philomene One;
    font-style: italic;
    font-weight: normal;
    font-size: 5.4em;
    text-transform: uppercase;
    transform: translate(-50%,0%) rotate(-11deg);
    color: black;
  }

  .podcast__titre-background {
    position:absolute;
    left:0%;
    top:0;
    height:100%;
     transform:translateX(0%);
    width:auto;
  }
  }
</style>