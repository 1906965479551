<template>
  <div class="card__container" :style="style">
    <div class="card__image-container">
      <img class="card__image" :src="pictureURL" />
      <img class="card__separator" v-if="backgroundColor == '#F42740'" src="./../../assets/images/home/cards/home-maincard-cut--right-red.svg" />
      <img class="card__separator" v-else src="./../../assets/images/home/cards/home-maincard-cut--right-dark.svg" />

    </div>
    <div class="box-card__text">
      <h2 class="box-card__titre">{{titre}}</h2>
      <div v-if="sameHours" class="box-card__plannings">
          <div class="box-card__days">
              <img class="box-card__planning-icone" src='./../../assets/images/icone/icon-agenda--grey.svg'/>
              <div class="box-card__planning-days">Tous les 
                <span class="box-card__day" v-for="planning in plannings" :key="planning.id">
                <b>{{getDay(planning.jour)}}</b>, 
                </span>
            </div>
            </div>
            <div class="box-card__hours">
                <img class="box-card__planning-icone" src='./../../assets/images/icone/icon-clock--grey.svg'/>
                <div>{{getHoursFromSame}}</div>

            </div>
        </div>
        <div v-else class="box-card__plannings">
            <div class="box-card__days" v-for="planning in plannings" :key="planning.id">
            <img class="box-card__planning-icone" src='./../../assets/images/icone/icon-agenda--grey.svg'/>
            <span class="box-card__day"><b>{{getDay(planning.jour)}}</b></span>
            <img class="box-card__planning-icone" src='./../../assets/images/icone/icon-clock--grey.svg'/>
            <div class="box-card__hours">{{getHours(planning)}}</div>
            </div>
        </div>
      <div class="icon-and-text-row-container">
        <p>En savoir plus</p>
        <img src="./../../assets/images/icone/icon-read-more-red.svg">
      </div>
    </div>
  </div>
</template>

<style scoped>

.card__container {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90em;
    border-radius:1em;
    flex-wrap : nowrap;
}

.card__image-container {
    flex:2;
    height:100%;
    border:none;
    position:relative;
}

.card__image {
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 1em;
}

.card__separator {
    position:absolute;
    height:100%;
    right:-1px;
    top:0;
}

.box-card__text {
    flex:1;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding:1em 1em 1em 1.2em;
    color:white;

}

.box-card__titre {
   font-size:3.8em;
   letter-spacing: 0.03em;
   font-style: normal;
   font-weight: normal;
   font-family: Chau Philomene One;
}


.box-card__plannings {
  font-size: 1.4em;
}

.box-card__days {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-start;
  margin-top:1em;
}

.box-card__planning-icone {
  margin-right:0.2em;
}

.box-card__hours {
    display:flex;
    flex-direction:row;
    margin-top:0.5em;
    align-items: center;
}

.icon-and-text-row-container {
    margin:2em 1em;
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
    color:#F42740;
    text-transform: uppercase;
    font-size:1.4em;
    letter-spacing: 0.03em;
    font-family: Chau Philomene One;
}

  .icon-and-text-row-container img {
    margin-left:1em;
  }

</style>

<script>
export default {
  name: 'HorizontalShowCard',
  props:{
    backgroundColor: String,
    imageNom: String,
    titre: String,
    sameHours : Boolean,
    plannings:Array
  },
  data() {
    return {

    }
  },
  computed: {
    getDay: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }; 
      return (day) => dictDay[day] ;
    },
    getHours: function(){
        return (hour) => hour['heure_debut'].substring(0,5);
    },
    getHoursFromSame:function() {
      if (this.plannings[0]){
        return this.plannings[0]['heure_debut'].substring(0,5);
      }
    },
    style(){
      return 'background-color:' + this.backgroundColor;
    },
    pictureURL(){
      if (this.imageNom.includes('http')){
        return this.imageNom;
      } else {
        return require(`@/assets/images/temp/${this.imageNom}`);
      }
    },

    /**
     * We check on mounted if all starting hours of the show are the same
     */

  },
}
</script>
