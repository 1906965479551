<template>
  <div class="podcastShow__container">
    <div v-if="podcastShow != null"  class="podcastParent__show">
      <router-link :to="{ name:'show-detail', params: {slug:podcastShow.slug}}">
        <HorizontalShowCard class="main__main-card" 
            backgroundColor="#233045" 
            :imageNom="podcastShow.image" 
            :titre="podcastShow.titre" 
            :plannings="plannings"
            :sameHours="sameHours"/>
      </router-link>
    </div>
    <div v-if="podcastArticle != null"  class="podcastParent__show">
      <router-link :to="{ name:'article-detail', params: {slug:podcastArticle.slug}}">
        <HorizontalArticleCard class="main__main-card" 
            backgroundColor="#233045" 
            :courteDescription="podcastArticle.courte_description"
            :imageNom="podcastArticle.image" 
            :titre="podcastArticle.titre" 
        />
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.podcastShow__container {
  margin:4.5em auto;
}


</style>
<script>
import HorizontalShowCard from '@/components/cards/HorizontalShowCard';
import HorizontalArticleCard from '@/components/cards/HorizontalArticleCard';

/**
 * @brief This is a section where we show the related parent card (either show or article)
 * @podcastShow The show link to the podcast if any
 * @podcastArticle the article link to the podcast if any
 * @plannings an array containing starting date and finishing date
 * @circleColor is passed to the RoundPicture components for background color
 */
export default {
  name:'PodcastParentDetail',
  props : {
    podcastShow:{
      type: Object,
    },
    podcastArticle: {
      type: Object
    },
    plannings: {
      type: Array
    },
    sameHours : {
      type: Boolean
    }
  },
  components : {
    HorizontalShowCard,
    HorizontalArticleCard
  }
}
</script>
