<template>
  <section class="road-shows">
    <div class="road-show__header">
      <img class="road-show__logo" src='./../../assets/images/home/agenda.png'/>
      <img class="road-show__separateur" src='./../../assets/images/home/home-cut-shape--roadshow-top-r.svg'/>
    </div>
    <div v-if="roadshows == null || roadshows.length < 1" class="road-show__list">
        <div class="no-roadshows">Pas de RoadShows prévus </div>
    </div>
    <div v-else>
      <div class="road-show__list">
        <div class="road-show_all-elements-container">
          <RoadEvent class="road-show__item" v-for="roadshow in firstLine" :key="roadshow.id"  v-bind:event="roadshow" />
        </div>
        <div v-if="secondLine !== null" class="road-show_all-elements-container">
          <RoadEvent class="road-show__item" v-for="roadshow in secondLine" :key="roadshow.id"  v-bind:event="roadshow" />
        </div>
      </div>
    </div>

  </section>
</template>

<style scoped>
.road-shows {
  margin-top:22em;
  text-align: center;
}

.road-show__header {
  position:relative;
  width:100%;
  background-color: #F42740;
}

.road-show__logo {
  z-index: 0;
  position: absolute;
  top:0;
  max-width: 400px;
  transform:translate(-50%,-100%);
}

.road-show__separateur {
  position:absolute;
  z-index:-1000;
  width:90vw;
  height:auto;
  top:0;
  right:0;
  transform:translate(0%,-99%);

}

.road-show__list {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding:1em;
  background-color: #F42740;
  color:white;
}

.road-show_all-elements-container {
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content: center;
  flex-wrap:wrap;
  margin: 15px 0px;
}

.road-show__item {
  max-width:19.4em;
  margin:0 2em;
}

.no-roadshows {
  margin-top:5vh;
  font-size: 2em;
}

</style>

<script>
import RoadEvent from '@/components/cards/RoadEvent';

export default {
  name:'Roadshow',
  props: {
    roadshows: Array
  },
  components: {
    RoadEvent,
  },
  computed: {
    firstLine() {
      return this.roadshows.slice(0,5);
    },
    secondLine() {
      if (this.roadshows.length >= 4) {
        return this.roadshows.slice(5,10);
      }
      return null;

    },
  }

}
</script>
