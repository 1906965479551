<template>
  <section class="partenaires">
    <div class="partenaires__header-background">
      <img class="partenaires__top-separator" src="./../../assets/images/home/partners/home-cut-shape--rpartners-top-r.svg">
      <img class="partenaires__cloud" src="./../../assets/images/home/partners/home-partners-clouds.svg">
    </div>
    <div class="partenaires__titre-container">
      <h2 class="partenaires__titre">Nos Partenaires !</h2>
    </div>
    <div class="partenaires__contenu">
      <p class="partenaires__texte">De nombreux partenaires soutiennent Radio RapTz, la rapporteuz d’informations, d’actions sociales, artistiques et culturelles et, diffuseuze de musiques eclectiques et a-typiques.</p>
      <div class="partenaires__liste">
        <div v-if="partenaires" v-for="partenaire in partenaires" :key="partenaire.id">
          <img class="partenaires__item" :src="partenaire.thumbnail" />
        </div>
        <div v-else>
          <img class="partenaires__item" src="./../../assets/images/home/partners/partner-repFr.png" />
          <img class="partenaires__item" src="./../../assets/images/home/partners/partner-culture.png" />
          <img class="partenaires__item" src="./../../assets/images/home/partners/partner-cget.png" />
          <img class="partenaires__item" src="./../../assets/images/home/partners/partner-seineStDenis.png" />
          <img class="partenaires__item" src="./../../assets/images/home/partners/partner-fondation-fr.png"/>
        </div>
      </div>
      <div class="partenaire-lien">
        <router-link class="partenaires__tous" :to="{ name:'partenaires'}">
          <img class="right-arrow" src="./../../assets/images/icone/icon-arrow-right--red.svg">
          <p class="">TOUS NOS PARTENAIRES</p>
        </router-link>
      </div>
    </div>
  
  </section>
  
</template>

<script>
export default {
  name:"Partenaires",
  props: {
      partenaires : Array
  },
}
</script>

<style>


.partenaires {
  margin-bottom:10em;
}

.partenaires__header-background {
  position:relative;
  width:100%;
  text-align:right;
  height:20em;
}

.partenaires__top-separator {
  position:absolute;
  top:0;
  right:0;
  width:65vw;
}

.partenaires__cloud {
  position:absolute;
  top:0;
  left:-5em;
  width:70%;
  margin:auto;
  overflow:hidden;
}

.partenaires__titre-container {
  width:100%;
  text-align:center;
}

.partenaires__titre {
  font-family: Chau Philomene One;
  font-weight: normal;
  font-size: 4.0em;
  text-transform: uppercase;
  color: #3A4D6C;
}

.partenaires__contenu {
  text-align:center;
}

.partenaires__texte {
  max-width:50%;
  margin:2em auto 0 auto;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 160%;
  text-align: center;
  color: #1A202A; 
}
.partenaires__liste {
  max-width: 100%;
  margin:2em auto 0 auto;
  background:rgb(247, 246, 246);
  opacity: 0.8;
  display:flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  background-blend-mode: overlay;
} 
 
.partenaires__item {
  margin-left:2em;
  margin-top:4em;
  width:20em;
  height:10em;
  object-fit: contain;
}

.partenaires__tous {

  width:20em;
  margin:4em auto 0 auto;
  padding:1em;
  border: 3px solid #F42740;
  box-shadow: 2px 2px 0px #F42740;
  border-radius: 5px;
  color:#F42740;

  display:flex;
  flex-direction:row;
  justify-content: center;
}

.partenaire-lien {    
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8em;

}
 @media screen and (min-width: 42em ) {
  .partenaires__cloud {
    position:absolute;
    top:0;
    left:-5em;
    width:auto;
    margin:auto;
    overflow:hidden;
  }
 }

 @media screen and (min-width: 96em ) {
  .partenaires__cloud {
    position:absolute;
    top:auto;
    left:0;
    width:auto;
    margin:auto;
    overflow:hidden;
  }

   .partenaires__liste {
    max-width: 117em;
    justify-content: space-between;
   }
 }

</style>