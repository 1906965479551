<template>
  <div class="content">
    <div class="FilterTags__drop-down-container" v-if="showItems">
      <div class="FilterTags__showDropDown" v-for="filterItem in filterList" :key="filterItem.id">
        <div class="FilterTags__item">
          <input v-model="selectedItems" v-on:change="clickFilter" type="checkbox" :id="filterItem.nom" :value="filterItem.nom" class="all-styles" />
          <label  :for="filterItem.nom">{{filterItem.nom}}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.content {
  width:auto;
  height:auto;
  font-family: Chau Philomene One;
  color: white;
  font-style: normal;
  font-weight: normal;
  background-color:#233045;
  width:100vw;
}

.FilterTags__drop-down-container{
  background-color:#233045;
  max-width:117em;
  margin:0 auto 0em auto;
  padding:1em;
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.FilterTags__showDropDown {
  text-align:center;
}

input.invisible {
  display:none;
}

input:checked + label {
  background-image: url("./../../assets/images/icone/icon-checked--red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  color:red;
  background-color:#233045;
}

input.all-styles {
  display:none;
}

label {
  float: left;
  padding:1.2em;
  border-radius:0px;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color:#76849C;
  background-color:#233045;
}

input:checked + label {
  background-image: url("./../../assets/images/icone/icon-checked--red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  color:white;
}

input:checked + label::before {
  /*no style*/
}

</style>
<script>
/**
 * The component get a list of items to render
 * It then wait for a list of item to be clicked to send all items back to parents
 * The component need handler in parents to stock the selected items
 */
export default {
  name:"FilterTags",
  data () {
    return {
      selectedItems: [],
      filteredSelectedItems:[]
    }
  },
  props : {
    filterList : Array,
    showItems : Boolean,
    filterName : String,
  },
  methods: {
    clickFilter (event) {
      this.filterArray();
      if(this.filterName == 'styles') {
          this.$emit('listOfStyle', this.selectedItems);
          this.$store.commit('setFilter', {
            nom: 'genres',
            arrayValues: this.filteredSelectedItems
            });
      } else if (this.filterName =='tags') {
          this.$store.commit('setFilter', {
            nom: 'tags',
            arrayValues: this.filteredSelectedItems
            });
      } else if (this.filterName =='localites') {
          this.$store.commit('setFilter', {
            nom: 'localites',
            arrayValues: this.filteredSelectedItems
            });
      }else if (this.filterName =='projets') {
          this.$store.commit('setFilter', {
            nom: 'projets',
            arrayValues: this.filteredSelectedItems
            });
      } else {
          console.log("filter categories array");
          this.$store.commit('setFilter', {
            nom: 'categories',
            arrayValues: this.filteredSelectedItems
            });
      }
    },

    /**
     * We need to filter what is pass back to back end to not have multiple elements in one tag
     */
    filterArray : function() {
      let subselection = []
      for (const el of this.filterList) {
        subselection.push(el.nom);
      }
      this.filteredSelectedItems = this.selectedItems.filter(el => subselection.includes(el))
      return this.filteredSelectedItems;
    }
  },
}
</script>