<template>
  <section class="podcastTitle__container">
    <div class="podcastTitle__info-container">
      <RoundPicture class="podcastTitle__roundPicture" v-bind:picture="podcastVignette" :backgroundColor='"gris"' size="big"/>
      <div class="podcastTitle__texte-container">
        <h2 class="podcastTitle__titre">{{podcastTitre}}</h2>
        <div class="podcastTitle__sous-titre"> Par <span class="bold">{{getName()}}</span></div>
        <div class="podcastTitle__date-container">
          <img src='./../../assets/images/icone/icon-clock--grey.svg'/>
          <div class="podcastTitle__date">{{podcast.date_publication}}</div>
        </div>
        <div v-if="podcast.show" class="podcastTitle__styles-container">
          <div class="podcastTitle__style-item" v-for="style in podcast.show.musique_styles" :key="style.id">{{style.nom}}</div>
        </div>
        <div v-else class="podcastTitle__styles-container">
          <div class="podcastTitle__style-item" v-for="style in podcast.musique_styles" :key="style.id">{{style.nom}}</div>
        </div>    
      </div>
    </div>
      <div  v-if="podcast.archive_podcast_embbeded" class="first-iframe" v-html="podcast.archive_podcast_embbeded">
      </div>
      <div v-else-if="podcast.podcast_embbeded !='' ">
        <iframe class="first-iframe" :src="podcast.podcast_embbeded" height="184px" width="100%"/>
      </div>
  </section>
</template>

<script>
import RoundPicture from '@/components/images/RoundPicture.vue';
import {getPodcastName} from '@/services/utils';

export default {
  name:'PodcastTitle',
  props : {
    podcast:{
      type:Object,
      required:true
    }
  },
  data() {
    return{
      show : null,
    }
  },
  methods: {
    getName(){
      return getPodcastName(this.podcast);
    },
  },
  computed:{
    podcastTitre(){
      return  this.podcast.titre == '' ? this.podcast.show.name + " #" + this.podcast.numero : this.podcast.titre;
    },
    podcastVignette(){
      return this.podcast.vignette == null ? this.podcast.show.vignette : this.podcast.vignette;
    },
    isAnSrc(){
      if (this.podcast.podcast_embbeded.includes('src')) {
        return false
      }
      return true;
    }

  },
  components:{
    RoundPicture,
  }
}

</script>

<style scoped>

.podcastTitle__container {
  max-width:117em;
  margin:0 auto;
}

.podcastTitle__roundPicture {
  width:26em;
  height:26em;
  margin:0;
}

.podcastTitle__info-container {
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items:flex-start;
  padding-left:1em;
  padding-right:1em;
  margin-bottom:5em;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
}

.podcastTitle__image {
  max-width: 48em;
  height:35vh;
  object-fit:cover;
  border-radius:1em;
}

.podcastTitle__texte-container{
  margin-left:5em;
  height:100%;
  max-width:56em;
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.8em;

}

.podcastTitle__titre {
  font-family: Chau Philomene One;
  font-size: 42px;
  line-height: 130%;
  text-align:left;
  letter-spacing: 0.01em;
  color: #233045;
}

.podcastTitle__sous-titre {

  font-size: 17px;
  line-height: 150%;
}

.podcastTitle__date-container {
  display:flex;
  margin-top:1em;
  align-items: center;
}

.podcastTitle__date {
  font-size: 17px;
  line-height: 150%;
  margin-left:1em;
}


.podcastTitle__styles-container {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  margin:2em 0;
}

.podcastTitle__style-item {
  margin: 0 1em 0 0em;
  padding:0.5em;
  color: white;
  font-size: 1.5em;
  border-radius:10em;
  background-image: url("./../../assets/images/icone/icon-checked--red.svg");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% auto;
}

.first-iframe {
  border-radius:10px;
  width:100%;
  background-color:white;
}

</style>


