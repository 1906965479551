import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/services/api';

Vue.use(Vuex)

/**
 * We use the store to manage global variables
 * We stock : articles, podcasts and plannings here once it is download
 */
const store = new Vuex.Store({
    state: {
        // We check if navigation is open for mobile
        isNavOpen:false,
        // Handles search modal
        isSearchModalOpen:false,
        searchString:"",

        //Three main objects
        podcasts:{},
        articles: {},
        shows:null,

        plannings:null,
        currentProg: {},

        //All the tags
        genres:[],
        categories:[],
        localites:[],
        tags:[],
        projets:[],

        //Cookies accepted
        cookiesConsent: false
    },

    getters: {
        isNavOpen: state => state.isNavOpen,
        isSearchModalOpen: state => state.isSearchModalOpen,
        getSearchString : state => state.searchString,
        articles: state => state.articles,
        podcasts: state => state.podcasts,
        genres: state => state.genres,
        categories: state => state.categories,
        localites: state => state.localites,
        tags : state => state.tags,
        projets : state => state.projets,
        currentProg: state => state.currentProg,
        cookieConsent: state => state.cookiesConsent
    },

    mutations: {
        toggleNav(state) {
            state.isNavOpen = !state.isNavOpen;
        },

        toggleSearchModal(state) {
            console.info('previous state search modal', state.isSearchModalOpen);
            state.isSearchModalOpen = !state.isSearchModalOpen;
        },

        setSearchValue(state, string) {
            state.searchString = string;
        },

        /**
         * @summary set the state of filters : categories or localites or genres
         * @param {Object} state of store
         * @param {Object} items must have a 'nom' property which correspond to filter list to set
         */
        setFilter(state, items) {
            //We need to check difference
            for (const tag of items.arrayValues) {
                if(!state[items.nom].includes(tag)) {
                    state[items.nom].push(tag);
                }
            }

            //We delete in first array what is not in the second array
            state[items.nom] = state[items.nom].filter(tag => items.arrayValues.includes(tag));
        },

        /**
         * @summary set podcast filter to one Item passed
         * @param {Object} state 
         * @param {String} properties 
         */
        setOneFilter(state, properties){
            state[properties.nom] = state[properties.nom].filter(item => {
                let found = false ;
                for(let style of item.musique_styles){
                    if (style.nom == properties.tag){
                        console.log(style.nom)
                        found = true;
                        break;
                    }
                }
                return found;
            });
        },

        setCookieConsent(state){
            state.cookiesConsent = true; 
        },

        resetFilter(state, filterName) {
            // We reset a filter without referencing to new item
            state[filterName] = state[filterName].splice(0, state[filterName].length)
        },
        resetArticles(state){
            state.articles = null; 
        },
        // TODO: it needs somme refactor
        setFilteredPodcasts(state, podcasts){
            Vue.set(state, 'podcasts', podcasts);
        },
        setFilteredShows(state, shows){
            Vue.set(state, 'shows', shows);
        },
        setFilteredArticle(state, articles){
            Vue.set(state, 'articles', articles);
        },
        setCurrentProg(state, prog){
            Vue.set(state,'currentProg', prog);
        }
    },

    // Nous mettons tous les appel asynchrone ici
    actions : {
        async fetchArticles({commit}, articleUrl  ){
            try {
                let response = await api.get(articleUrl);
                commit('setFilteredArticle',  response.data['results']);
            } catch (e) {
                console.error(e);
            }
        },
        
        async fetchCurrentProg({commit}){
            try {
                api.get('planning/now').then((response) => {
                    commit('setCurrentProg', response['data']);
                });   
            } catch (e) {
                console.error(e);
            }
        }
    }

})

export default store;
