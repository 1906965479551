<template>
  <div v-if="this.$store.getters.cookieConsent == false" class="cookies">
    <h2 class="titre_cookies">Cookies</h2>
    <p class="texte_cookies">En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies pour mesurer l'audience du site.</p>
    <div class="bouton_cookies">
      <button v-on:click="enableCookies()">Accepter les cookies</button> | 
      <button v-on:click="refuseCookies()">Refuser les cookies</button>
    </div>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag';
import JSCookies from 'js-cookie';

export default {
  name: 'Cookies',
  methods: {
    enableCookies () {
      bootstrap().then((gtag) => {
        console.log(gtag)
      })
      this.$store.commit('setCookieConsent');
      JSCookies.set("consent-cookie", 'raptz.com', { expires: 31 });
    },
    refuseCookies () {
      this.$store.commit('setCookieConsent');
    },
    showCookieConsent() {
      console.log("JSCookie get", JSCookies.get("consent-cookie"));
      if(JSCookies.get("consent-cookie") === 'raptz.com'){
        this.$store.commit('setCookieConsent');
      } 
      console.log('has no cookie', this.hasNoCookie);
    }
  },
  created(){
    this.showCookieConsent();
  }
}
</script>

<style scoped>

@media screen and (min-width: 1270px ) {

  .cookies{
    position: fixed;
    margin-left: -1px;
    bottom:0;
    width: 100%;
    background-image: url('./../../assets/images/player/player-bg--mobile.svg');
    background-size:100%;
    background-repeat: no-repeat;
    z-index:400;
    color: white;

  }

  .texte_cookies{
    font-size: 1.4em;
    margin-bottom: 1%;
    margin-left: 1%
  }

  .titre_cookies{
    margin-top: 1%;
    margin-left: 1%;
    color: #F42740;
  }
  
  .bouton_cookies{
    margin-left: 33%;
    margin-bottom: 1%;
  }

}

@media screen and (min-width: 0px ) and (max-width: 1280px) {  

  .cookies{
    position: fixed;
    margin-left: -1px;
    bottom:0;
    width: 101%;
    background-color: #233045;
    background-repeat: no-repeat;
    z-index:400;
    color: white;
    text-align: center;

  }

  .texte_cookies{
    font-size: 1.4em;
    margin-bottom: 1%;
    margin-left: 1%
  }

  .titre_cookies{
    margin-top: 1%;
    margin-left: 1%;
    color: #F42740;
  }
  
  .bouton_cookies{
    margin-bottom: 1%;
  }

}
</style>