<template>
  <div class="card-container">
    <h3 class="partner__nom" v-if="partenaire.nom">{{partenaire.nom}}</h3>
    <div class="partner-description">
      <SquarePicture
        v-if="partenaire.thumbnail"
        v-bind:picture="partenaire.thumbnail"
        class="PartnerCard__square-picture"
      />
      <div class="description-txt" v-html="partenaire.description"></div>
    </div>
  </div>
</template>


<style scoped>
.card-container {
  max-width: 600px;
  min-width: 500px;
  margin: 0em auto 2em auto;
  padding: 1em;
  border-bottom: 2px solid #dfe0e2;
}

.partner__nom {
  font-family: var(--typo-title);
  font-size: 2.8em;
  color: #f42740;
  margin-bottom: 0.2em;
}

.partner-description {
  /*   display: flex;  */
  margin-top: 1em;
  margin-bottom: 2em;
}

.description-txt {
  /*   margin-left: 1em; */
  font-size: 14px;
  text-align: justify;
}

.PartnerCard__square-picture {
  width: 18em;
  object-fit: contain;
  border-radius: 0px;
  margin-bottom: 1em;
}
</style>

<script>
import SquarePicture from "@/components/images/SquarePicture";

export default {
  name: "PartenaireCard",
  props: {
    partenaire: Object,
  },
  data() {
    return {};
  },
  components: {
    SquarePicture,
  },
};
</script>

