<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero"/>
    <div class="FilterTags__filters-container">
      <a  v-on:click="CheckDropDownStyle">
        <div class="FilterTags__selector-container">
            <h4 class="FilterTags__selector">Styles musicaux</h4>
            <img v-if="showStylesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
            <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
        </div>
      </a>
      <a v-on:click="CheckDropDownCategories">
        <div class="FilterTags__selector-container">
            <h4 class="FilterTags__selector">Categories</h4>
            <img v-if="showCategoriesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
            <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
        </div>
      </a>
      <a v-on:click="CheckDropDowLocalite">
        <div class="FilterTags__selector-container">
            <h4 class="FilterTags__selector">Localites</h4>
            <img v-if="showLocalitesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
            <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
        </div>
      </a>
    </div>
    <FilterTags class="FilterTags__filter" :filterList="passList" :showItems="showDropDown" :filterName="filteringName" :selectedFilteredList="selectedFilteredList"/>
    <div class="podcasts__list">
      <div v-if="podcasts">
        <div class="shaped__backgrounds">
          <ul>
            <transition-group name="fade" mode="out-in">
              <li class="podcast__card" v-for="podcast in selectAllPodcasts" :key="podcast.id">
                <PodcastCard  class="card-container" :podcast="podcast" />
              </li>
            </transition-group>
          </ul>
        </div>
        <div class="pagination">
          <div v-if="previous" class="previous" v-on:click="getPreviousItems"><img src="./../assets/images/icone/icon-arrow--previous.svg"/></div>
          <div class="current">{{current_page}}</div>
          <div v-if="next" class="next" v-on:click="getNextItems"><img src="./../assets/images/icone/icon-arrow--next.svg"/></div>
        </div>
      </div>
      <div v-else >Podcasts loading</div>  
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import axios from 'axios';
import MainHero from '@/components/hero/MainHero';
import RoundPicture from '@/components/images/RoundPicture';
import PodcastCard from '@/components/cards/PodcastCard';
import FilterTags from '@/components/sections/FilterTags';

export default {
  name: 'Programme',
  components: {
    MainHero,
    RoundPicture,
    PodcastCard,
    FilterTags
  },
  data() {
    return {
      previous : null,
      next : null,
      total_pages: null,
      current_page: null,
      filteringName : null,
      podcasts: null,
      showStylesDropDown :false,
      showCategoriesDropDown : false,
      showLocalitesDropDown: false,
      listOfStyles :null,
      listOfCategories : null,
      listOfLocalites : null,
      selectedFilteredList : null,
    
      hero: {
          imageHD : "images/podcast/hero-podcasts--l.jpg",
          imageM : "images/podcast/hero-podcasts--l.jpg",
          imageLD : "images/podcast/hero-podcasts--s.jpg",
          titre : "Podcasts",
          filtre: "blue"
      }
    }
  },
  methods : {
    getNextItems() {
        let relativeUrl = this.next.substring(this.next.indexOf('api/') + 'api/'.length); 
        return api.get(relativeUrl).then(response => {
          this.$store.commit('setFilteredPodcasts', response.data['results']);
          this.podcasts = this.$store.getters.podcasts;
          this.previous = response.data['previous'];
          this.current_page = response.data['current_page'];
          this.next = response.data['next'];
        })
    },

    getPreviousItems() {
        let relativeUrl = this.previous.substring(this.previous.indexOf('api/') + 'api/'.length); 
        return api.get(relativeUrl).then(response => {
          this.$store.commit('setFilteredPodcasts', response.data['results']);
          this.podcasts = this.$store.getters.podcasts;
          this.previous = response.data['previous'];
          this.current_page = response.data['current_page'];
          this.next = response.data['next'];
        })
    },
    /**
     * @summary it should return the selected page in the list
     getPageItems(){
      let page = this.$ref
      let relativeUrl = '?page=';
    },
    */
    


    fetchPodcastsAndFilters() {
      const requestedPodcasts= api.get(`podcasts/`);
      const requestedStyles= api.get(`styles/filtrables`);
      const requestedCategories= api.get(`categories/filtrables`);
      const requestedLocalites= api.get(`localites/filtrables`);

      return axios.all([requestedPodcasts, requestedStyles, requestedCategories, requestedLocalites ])
        .then(axios.spread((...responses) => {
          this.podcasts = responses[0].data['results'];
          this.$store.commit('setFilteredPodcasts', responses[0].data['results']);
          this.previous = responses[0].data['previous'];
          this.next = responses[0].data['next'];
          this.total_pages = responses[0].data['total_pages'];
          this.current_page = responses[0].data['current_page'];
          this.listOfStyles= responses[1].data['results'];
          this.listOfCategories = responses[2].data['results'];
          this.listOfLocalites = responses[3].data['results'];
        })).catch(errors => {
          console.log(errors)
        })
    },

    CheckDropDownStyle:function() {
      this.showStylesDropDown=!this.showStylesDropDown; 
      this.filteringName='styles'; 
      this.selectedFilteredList = this.listOfStyles;
      if (this.showStylesDropDown == true && (this.showCategoriesDropDown==true || this.showLocalitesDropDown==true)) {
        this.showCategoriesDropDown = false ;
        this.showLocalitesDropDown = false ;
      } 
    },

    CheckDropDownCategories:function(){
      this.showCategoriesDropDown=!this.showCategoriesDropDown; 
      this.filteringName='categories'; 
      this.selectedFilteredList = this.listOfCategories;
      if (this.showCategoriesDropDown == true && (this.showStylesDropDown==true || this.showLocalitesDropDown==true)) {
        this.showStylesDropDown = false ;
        this.showLocalitesDropDown = false ;
      } 
    },

    CheckDropDowLocalite: function(){
      this.showLocalitesDropDown=!this.showLocalitesDropDown; 
      this.filteringName='localites'; 
      this.selectedFilteredList = this.listOfLocalites;
      if (this.showLocalitesDropDown == true && (this.showStylesDropDown==true || this.showCategoriesDropDown==true)) {
        this.showStylesDropDown = false ;
        this.showCategoriesDropDown = false ;

      } 
    },


    /**
     * @summary the main function to return articles with corresponding filters
     */
    getFilteredPodcasts: function(){
      let categoriesParams = this.$store.getters.categories ;
      let localitesParams = this.$store.getters.localites ;
      let genreParams = this.$store.getters.genres;

      let podcastURL = 'podcasts/?';
      let hasAlreadyFilter = false ;

      if (categoriesParams.length > 0 ){
        hasAlreadyFilter = true ;
        podcastURL += 'categories='+ categoriesParams.join(',');
      } 

      if (localitesParams.length > 0) {
        if (hasAlreadyFilter == true) {
          podcastURL += '&';
        }
        hasAlreadyFilter = true ;
        podcastURL += 'localites='+ localitesParams.join(',');
      }

      if (genreParams.length > 0) {
        if (hasAlreadyFilter == true) {
          podcastURL += '&';
        }
        podcastURL += 'genres='+ genreParams.join(',');
      }


      api.get(podcastURL).then((response) => {
          this.$store.commit('setFilteredPodcasts', response.data['results']);
        });
      this.podcasts = this.$store.getters.podcasts ;
      return this.podcasts ;
    },
  },

  computed: {
    selectAllPodcasts: function(){
      return this.$store.getters.podcasts;
    },

    passList: function() {
      let listItem ;
      if(this.filteringName == 'styles'){
         listItem = this.listOfStyles; 
      } else if(this.filteringName == 'localites') {
        listItem = this.listOfLocalites;
      } else {
        listItem = this.listOfCategories;
      }
      return listItem;
    },

    showDropDown: function() {
      if (this.showCategoriesDropDown || this.showStylesDropDown || this.showLocalitesDropDown) {
        return true ;
      }
      return false ;
    }
  }, 

  created() {
    this.$store.watch((state, getters) => getters.categories, () => {
      this.getFilteredPodcasts();
    })
    this.$store.watch((state, getters) => getters.genres, () => {
      this.getFilteredPodcasts();
    })
    this.$store.watch((state, getters) => getters.localites, () => {
      this.getFilteredPodcasts();
    })
    this.fetchPodcastsAndFilters();
  },
  /**
   * TODO better destroy watcher
   */
  beforeDestroy(){
  }
};
</script>

<style scoped>

.podcasts__list {
  margin-top:5em;
}

.podcast__card{
  display:flex;
  position:relative;
  align-items: top;
  padding:0em 2em;
  max-width: 857px;
  min-width: 700px;
  height: 20em;
  margin:0em auto 2em auto;
  padding:0 auto 2em auto;
  border-bottom: 2px solid #DFE0E2;
  list-style: none;
}

.card-container {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding:1em;
  width:100%;
}

</style>
