<template>
  <section class="description">
      <section class="description margin-wrapper"  v-html="content.description"></section>
  </section>
</template>

<script>
/**
 * This component is used to render all rich text description
 * It takes a rich-text argument
 * 
 */
export default {
  name:'ContentTexte',
  props : {
    content: Object
  },
  components:{
  }
}

</script>

<style scoped>
/** CONTENT PAGE **/
  .description {
    margin : 5em auto;
    max-width:75em;
  }

  .description >>> h2 {
    margin-top:1em;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5em;
    letter-spacing: 0.03em;
  }

   .description >>> h3 {
    margin-top:1em;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5em;
    letter-spacing: 0.03em;
  }


  .description >>> p {
    margin-top:1em;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.9em;
  }

  .description >>> a {
    color:#993366;
    font-weight:bold;
  }

@media screen and (min-width: 800px ) {

  .description >>> iframe {
    width:600px;
    height:400px;
  }
}

@media screen and (max-width: 800px ) {
  .description >>> iframe {
    width:400px;
    height:400px;
  }
}

</style>

