<template>
  <div class="content">
    <router-link :to="{ name:'roadshow-detail', params: {slug: event.rs_slug }}">
      <div class="event__container">
        <div v-if="computedIsOneDay()"  class="date__container">
          <div  class="day__container">
            <img class="icone" src="./../../assets/images/icone/bg-shape-agenda-day.svg">
            <div class="event-day">{{eventDay}}</div>
          </div>  
          <div class="column__container">
            <div class="event-month">{{eventMonth}}</div>
            <div class="event-year">{{eventYear}}</div>
          </div>
        </div>
        <div v-else class="date__container">
          <div  class="day__container">
            <img class="icone" src="./../../assets/images/icone/bg-shape-agenda-days.svg">
            <div class="event-day">{{eventEnd}}</div>
          </div>
          <div class="column__container-days">
            <div class="event-month">{{eventEndMonth}}</div>
            <div class="event-year">{{eventYear}}</div>
          </div> 
        </div>
        <div class="event-name">{{event.nom}}</div>
        <div class="event-location">{{event.lieu}}</div>
      </div>
    </router-link>
  </div>

</template>

<script>
export default {
  name: "RoadEvent",
  props: {
    event: Object,
  },
  data() {
    return {

      dictMonth: {
        "01":"JANVIER",
        "02":"FEVRIER",
        "03":"MARS",
        "04":"AVRIL",
        "05":"MAI",
        "06":"JUIN",
        "07":"JUILLET",
        "08":"AOU",
        "09":"SEP",
        "10":"OCT",
        "11":"NOV",
        "12":"DEC"
      }
    }
  },
  methods : {
      computedIsOneDay(){
        let day = this.event.date ;
        let dayEnd = this.event.date_fin;
        if(dayEnd == null) {
          return true ;
        }
        return (day == dayEnd) ;
      }
  },
  computed:{
    eventDay: function(){
      let day =  this.event.date.substring(8,10) ;
      return day;
    },
    eventMonth: function(){
      let month =  this.event.date.substring(5,7) ;
      return this.dictMonth[month];
    },
    eventYear:function(){
      let year = this.event.date.substring(2,4);
      return '20'+year;
    },
    eventEnd: function(){
      let day = this.event.date.substring(8,10) ;
      let dayEnd = this.event.date_fin.substring(8,10);
      let dateString = day + ' au ' + dayEnd ;
      return dateString;
    },
    eventEndMonth: function(){
      let month =  this.event.date.substring(5,7) ;
      let monthEnd = this.event.date_fin.substring(5,7);
      if(month === monthEnd ) {
        return this.dictMonth[month]
      }
      return this.dictMonth[month] + '-' + this.dictMonth[monthEnd] ;
    }
  },
}
</script>

<style scoped>
  .content {
    background-color:#F42740;
    color:black;
    text-align:left;

  }

  .date__container {
    display:flex;
    flex-direction: row;
  }

  .column__container {
    display:flex;
    flex-direction: column;
  }

  .column__container-days {
    width:8em;
  }

  .day__container {
    position:relative;
    text-align:center;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;

  }

  .event-day {
    position:absolute;
    top:0;
    left:50%;
    transform:translate(-50%, 15%);
    font-size: 2.7em;
    letter-spacing: 0.02em;
    width:100%;
  }

  .event-month {
    font-size: 1.6em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-left:0.5em;
    color:white;
    max-width:20em;
    display:inline;
    
  }

  .event-year {
    font-size: 1.6em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-left:0.5em;
    color:white;
  }

  .event-name {
    font-family: Noto Sans;
    font-size: 1.6em;
  }

  .event-location {
    font-family: Noto Sans;
    font-size: 1.6em;
  }

  .icone-long {
    width:10em;
    max-height:5em;
    object-fit: cover;
  }

</style>