import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/Home';
import ListeProjet from '@/views/ListeProjet';
import ListeLocalite from '@/views/ListeLocalite';
import Articles from '@/views/Articles';
import Podcasts from '@/views/Podcasts';
import Programme from '@/views/Programme';
import Raptz from '@/views/Raptz';
import Partenaires from '@/views/Partenaires';
import Shows from '@/views/Shows';
import ShowDetail from '@/views/ShowDetail';
import ShowPodcasts from '@/views/ShowPodcasts';
import PodcastDetail from '@/views/PodcastDetail';
import ArticleDetail from '@/views/ArticleDetail';
import RoadShowDetail from '@/views/RoadShowDetail';
import SearchResults from '@/views/SearchResults';
import PageNotFound from '@/views/PageNotFound';


Vue.use(Router);


export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/actions',
      name: 'article',
      component: Articles
    },    
    //Nous effecutons les redirections des anciennes urls pour shows, progs et deux types d'actions
    {
      path: "/shows-info-download",
      redirect: '/shows'
    },
    {
      path: "/grille-horaire-grid",
      redirect: '/prog'
    },
    {
      path: "/street-radio-actions",
      redirect: '/actions'
    },
    {
      path: "/articles-2/societe",
      redirect: '/actions'
    },
    //Fin des redirections
    {
      path: "/action/:slug",
      name: 'article-detail',
      component: ArticleDetail,
      props:true
    },
    {
      path:"/actions/projet/:slug",
      name: 'articles-projet',
      component:ListeProjet,
      props:true
    },
    {
      path:"/actions/localite/:slug",
      name: 'articles-localite',
      component:ListeLocalite,
      props:true
    },
    {
      path: '/raptz',
      name: 'raptz',
      component: Raptz
    },
    {
      path: "/shows",
      name: "shows",
      component: Shows
    },
    {
      path: "/show/:slug/podcasts",
      name: "show-podcasts",
      component: ShowPodcasts,
      props:true
    },
    {
      path: "/shows/:slug",
      name: "show-detail",
      component: ShowDetail,
      props: true
    },

    {
      path: '/prog',
      name: 'programme',
      component: Programme
    },
    {
      path: '/podcasts',
      name: 'podcasts',
      component: Podcasts
    },
    {
      path: "/podcast/:slug",
      name: 'podcast-detail',
      component: PodcastDetail,
      props:true
    },
    {
      path: '/roadshow/:slug',
      name: 'roadshow-detail',
      component: RoadShowDetail,
      props:true
    },
    {
      path: "/partenaires",
      name: 'partenaires',
      component: Partenaires,
      props:false
    },
    {
      path: "/search",
      name: 'search-results',
      component: SearchResults,
      props:true
    },

    //La redirection des urls des anciens articles
    {
      path:"/:slug",
      redirect: "/action/:slug",
      props:true
    },

    //Gestion des erreurs
    { 
      path: '/404', 
      name: 'error',
      component: PageNotFound 
    },  
    {
      path: "*", 
      redirect:'/404' 
    }
  ]
});
