<template>
  <section class="showTitle__container">
    <img class="showTitle__image" :src="show.image"/>
    <div class="showTitle__texte">
      <h1 class="showTitle__titre">{{show.titre}}</h1>
      <div class="showTitle__artist">
        <span v-if="show.artist.alias"><b>Par {{show.artist.alias}}</b></span>
        <span v-else><b>Par Raptz</b></span>
      </div>
      <div v-if="sameHours" class="showTitle__plannings">
        <div class="showTitle__days">
          <img class="showTitle__planning-icone" src='./../../assets/images/icone/icon-agenda--dark.svg'/>
          <div class="showTitle__planning-days">
            <span class="showTitle__day" v-for="planning in plannings" :key="planning.id">
              <b>{{getDay(planning.jour)}}</b>, 
            </span>
          </div>
        </div>
        <div class="showTitle__hours">
            <img class="showTitle__planning-icone" src='./../../assets/images/icone/icon-clock--dark.svg'/>
            <div v-if="plannings[0]">{{getHours(plannings[0]['heure_debut'])}}</div>
      
        </div>
      </div>
      <div v-else class="showTitle__plannings">
        <div class="showTitle__days" v-for="planning in plannings" :key="planning.id">
          <img class="showTitle__planning-icone" src='./../../assets/images/icone/icon-agenda--dark.svg'/>
          <span class="showTitle__day"><b>{{getDay(planning.jour)}}</b></span>
          <img class="showTitle__planning-icone" src='./../../assets/images/icone/icon-clock--dark.svg'/>
          <span class="showTitle__day">{{getHours(planning['heure_debut'])}}</span>
        </div>
      </div>
      <div class="showTitle__styles">
        <div v-if="show.musique_styles">
          <div class="showTitle__style" v-for="style in show.musique_styles" :key="style.id">
            {{style.nom}}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SquarePicture from '@/components/images/SquarePicture.vue';

/**
 * The component take a show and its plannings to display
 */
export default {
  name:'ShowTitle',
  props : {
    show:{
      type:Object,
      required:true
    },
    plannings:{
        type:Array,
    }
  },
  data() {
    return {
      sameHours:false,
    }
  },
  components:{
    SquarePicture,
  },
  computed : {
    getDay: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }; 
      return (day) => dictDay[day] ;
    },
    getHours: function(){
      return (hour) => hour.substring(0,5);
    },
  },
  methods : {
    /**
     * We check on mounted if all starting hours of the show are the same
     */
    getSameHours: function(){
      let arrayOfHours = [];
      for (let planning of this.plannings) {
        arrayOfHours.push(planning.heure_debut);
      }
      this.sameHours = arrayOfHours.every((val, i, arr) => val === arr[0])
    }
  },
  mounted() {
    this.getSameHours();
  }
}
</script>

<style scoped>

.showTitle__container {
  max-width:117em;
  margin:0 auto;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  font-family: Noto Sans;

}

.showTitle__image {
  width: 59em;
  height:100%;
  object-fit: contain;
  border-radius:0.8em;
}

.showTitle__texte {
  margin-left:5em;
  max-width: 53em;
  text-align: left;
}

.showTitle__titre {
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.03em;
  font-size:4.0em;
}

.showTitle__artist {
  font-size: 1.8em;
}

.showTitle__days {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-start;
  margin-top:0.5em;
}

.showTitle__day {
  margin-right:0.5em;
}

.showTitle__plannings {
  font-size: 1.8em;
}

.showTitle__planning-icone {
  margin-right:0.5em;
}
.showTitle__hours {
  display:flex;
  flex-direction:row;
  margin-top:0.5em;
  align-items: center;
}

.showTitle__styles {
  margin-top:1.2em;
  font-size: 1.2em;
  width:100%;
}

.showTitle__style {
  margin: 0 0.5em 0 0em;
  padding:0.5em;
  color: #657388;
  font-size: 1.4em;
  border-radius:10em;
  background-image: url("./../../assets/images/icone/item-background-grey.svg");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: contain;
  font-family: Noto Sans;
  float:left;
}

</style>

