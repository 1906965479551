<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero"/>
      <div class="FilterTags__filters-container">
        <a class="FilterTags__clickArea" v-on:click="CheckDropDownCategories">
          <div class="FilterTags__selector-container">
              <h4 class="FilterTags__selector">Categories</h4>
              <img v-if="showCategoriesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
              <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
          </div>
        </a>
        <a class="FilterTags__clickArea" v-on:click="CheckDropDownLocalites">
          <div class="FilterTags__selector-container">
              <h4 class="FilterTags__selector">Localités</h4>
              <img v-if="showLocalitesDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
              <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
          </div>
        </a>
        <a class="FilterTags__clickArea" v-on:click="CheckDropDownProjets">
          <div class="FilterTags__selector-container">
            <h4 class="FilterTags__selector">Projets</h4>
            <img v-if="showProjetsDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
            <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
          </div>
        </a>
        <a class="FilterTags__clickArea" v-on:click="CheckDropDownTags">
          <div class="FilterTags__selector-container">
            <h4 class="FilterTags__selector">Tags</h4>
            <img v-if="showTagsDropDown" class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-up-red.svg">
            <img v-else class="FilterTags__selector-icone" src="./../assets/images/icone/icon-arrow-down-red.svg">
          </div>
        </a>
      </div>
      <FilterTags class="FilterTags__filter" :filterList="passList" :showItems="showDropDown" :filterName="filteringName" :selectedFilteredList="selectedFilteredList" />
      <div class="podcasts__list">
        <div v-if="articles">
          <div class="shaped__backgrounds">
            <ul>
              <transition-group name="fade" mode="out-in">
                <li class="podcast__card" v-for="article in selectedAllArticles" :key="article.id">
                  <router-link :to="{ name:'article-detail', params: {slug: article.slug}}">
                    <ArticleCard  class="card-container" :item="article" />
                  </router-link>
                </li>
              </transition-group>
            </ul>
          </div>
          <div class="pagination">
            <div v-if="previous" class="previous" v-on:click="getPreviousItems"><img src="./../assets/images/icone/icon-arrow--previous.svg"/></div>
            <div class="current">{{current_page}}</div>
            <div v-if="next" class="next" v-on:click="getNextItems"><img src="./../assets/images/icone/icon-arrow--next.svg"/></div>
          </div>
        </div>
        <div v-else class="loading" >Podcasts loading</div>  
    </div>
  </div>
</template>

<script>
/**
 * This view, show all the articles using 
 *  @ArticleCard component which need an single article as parameter
 *  @FilterTags component on categories and localites
 *  @MainHero component for the hero image 
 * 
 */

import api from '@/services/api' ;
import axios from 'axios';
import MainHero from '@/components/hero/MainHero';
import ArticleCard from '@/components/cards/ArticleCard';
import FilterTags from '@/components/sections/FilterTags';

export default {
  name: 'Articles',
  components: {
    MainHero,
    ArticleCard,
    FilterTags
  },
  data() {
    return {
      articles: null,
      previous : null,
      next : null,
      current_page: null,
      showLocalitesDropDown :false,
      showCategoriesDropDown : false,
      showTagsDropDown : false,
      showProjetsDropDown: false,
      filteringName : null,
      listOfCategories : null,
      listOfLocalites : null,
      listOfTags : null,
      listOfProjets : null,
      selectedFilteredList : null,
      hero: {
        imageHD : "images/action/hero-articles-v2.jpg",
        imageM : "images/action/hero-articles-v2.jpg",
        imageLD : "images/action/hero-articles--s.jpg",
        titre : "Ateliers",
        filtre: 'red'
      }
    }
  },

   methods : {
     /**
      * We use drf pagination style to get informations
      * Each page of drf with pagination contain url of next and previous
      * TODO : Methods need to be in a helper function
      */
    getNextItems() {
        let relativeUrl = this.next.substring(this.next.indexOf('api/') + 'api/'.length); 
        return api.get(relativeUrl).then(response => {
          this.$store.commit('setFilteredArticle', response.data['results']);
          this.articles = this.$store.getters.articles;
          this.previous = response.data['previous'];
          this.current_page = response.data['current_page'];
          this.next = response.data['next'];
        })
    },

    /**
      * We use drf pagination style to get informations
      * Each page of drf with pagination contain url of next and previous
      * TODO : Methods need to be in a helper function
      */
    getPreviousItems() {
        let relativeUrl = this.previous.substring(this.previous.indexOf('api/') + 'api/'.length); 
        return api.get(relativeUrl).then(response => {
          this.$store.commit('setFilteredArticle', response.data['results']);
          this.articles = this.$store.getters.articles;
          this.previous = response.data['previous'];
          this.current_page = response.data['current_page'];
          this.next = response.data['next'];
        })
    },

     /**
      * It fetch articles, categories and localites
      * It fetches values for next and previous pagination
      * 
      * @returns articles, listOfLocalites, listOfCategories datas
      */
    fetchArticlesAndFilters() {

      const requestedArticles= api.get(`articles/`);
      const requestedLocalites= api.get(`localites/filtrables`);
      const requestedCategories= api.get(`categories/filtrables`);
      const requestedProjets= api.get(`projets/filtrables`);
      const requestedTags= api.get(`tags/filtrables`);

      return axios.all([requestedArticles, requestedLocalites, requestedCategories, requestedProjets, requestedTags ])
        .then(axios.spread((...responses) => {
          this.$store.commit('setFilteredArticle', responses[0].data['results']);
          this.articles = this.$store.getters.articles;
          this.previous = responses[0].data['previous'];
          this.current_page = responses[0].data['current_page'];
          this.next = responses[0].data['next'];
          this.listOfLocalites= responses[1].data['results'];
          this.listOfCategories = responses[2].data['results'];
          this.listOfProjets = responses[3].data['results'];
          this.listOfTags = responses[4].data['results'];

        })).catch(errors => {
          console.log(errors)
        })
    },



    /**
     * It verify the state of the DropDownCategories button and changed state of all filters according to it
     * Reminder : There can be only one filter shown at a time even if they can all be applied
     */
    CheckDropDownCategories:function(){
      this.showCategoriesDropDown=!this.showCategoriesDropDown; 
      this.filteringName='categories'; 
      this.selectedFilteredList = this.listOfCategories;
      if (this.showCategoriesDropDown == true && (this.showLocalitesDropDown==true || this.showTagsDropDown == true || this.showProjetsDropDown == true)) {
        this.showLocalitesDropDown = false ;
        this.showTagsDropDown = false ;
        this.showProjetsDropDown = false ; 
      } 
    },

        /**
     * It verify the state of the DropDownLocalites button and changed state of all filters according to it
     * Reminder : There can be only one filter shown at a time even if they can all be applied
     */
    CheckDropDownLocalites: function() {
      this.showLocalitesDropDown=!this.showLocalitesDropDown; 
      this.filteringName='localites'; 
      this.selectedFilteredList = this.listOfLocalites;
      if (this.showLocalitesDropDown == true && (this.showTagsDropDown == true || this.showCategoriesDropDown==true || this.showProjetsDropDown == true )) {
        this.showCategoriesDropDown = false ;
        this.showTagsDropDown = false;
        this.showProjetsDropDown = false ;
      } 
    },

    
    /**
     * It verify the state of the DropDownProjets button and changed state of all filters according to it
     * Reminder : There can be only one filter shown at a time even if they can all be applied
     */
    CheckDropDownProjets: function() {
      this.showProjetsDropDown=!this.showProjetsDropDown; 
      this.filteringName='projets'; 
      this.selectedFilteredList = this.listOfProjets;
      if (this.showProjetsDropDown == true && (this.showTagsDropDown == true || this.showLocalitesDropDown==true || this.showCategoriesDropDown == true)) {
        this.showCategoriesDropDown = false ;
        this.showTagsDropDown = false;
        this.showLocalites = false ;
      } 
    },

    /**
     * It verify the state of the DropDownTags button and changed state of all filters according to it
     * Reminder : There can be only one filter shown at a time even if they can all be applied
     */
    CheckDropDownTags:function() {
      this.showTagsDropDown=!this.showTagsDropDown; 
      this.filteringName='tags'; 
      this.selectedFilteredList = this.listOfTags;
      if (this.showTagsDropDown == true && (this.showLocalitesDropDown == true || this.showCategoriesDropDown==true || this.showProjetsDropDown == true)) {
        this.showLocalitesDropDown = false ;
        this.showCategoriesDropDown = false ;
        this.showProjetsDropDown = false ;
      } 
    },

    /**
     * @summary the main function to return articles with corresponding filters
     */
    getFilteredArticles: function(){
      let categoriesParams = this.$store.getters.categories ;
      let localitesParams = this.$store.getters.localites ;
      let tagsParams = this.$store.getters.tags;
      let projetsParams = this.$store.getters.projets;

      let articleURL = 'articles/?';
      let hasAlreadyFilter = false ;

      if (categoriesParams.length > 0 ){
        hasAlreadyFilter = true ;
        articleURL += 'categories='+ categoriesParams.join(',');
      }

      if (tagsParams.length > 0) {
        if (hasAlreadyFilter == true) {
          articleURL += '&';
        }
        articleURL += 'tags='+ tagsParams.join(',');
      }

      if (projetsParams.length > 0) {
        if (hasAlreadyFilter == true) {
          articleURL += '&';
        }
        articleURL += 'projets='+ projetsParams.join(',');
      }


      if (localitesParams.length > 0) {
        if (hasAlreadyFilter == true) {
          articleURL += '&';
        }
        articleURL += 'localites='+ localitesParams.join(',');
      }

      api.get(articleURL).then((response) => {
          this.$store.commit('setFilteredArticle', response.data['results']);
        });
      this.articles = this.$store.getters.articles ;
      return this.articles ;
    },

  },

  computed: {
    /**
     * @returns all articles filtered to be shown in article views
     */
    selectedAllArticles: function(){
      return this.$store.getters.articles;
    },

    /**
     * This computed function pass the right arguments
     */
    passList: function() {
      let listItem ;
      if (this.filteringName == 'localites') {
        listItem = this.listOfLocalites;
      } else if (this.filteringName == 'categories') {
        listItem = this.listOfCategories ;
      } else if (this.filteringName == 'projets'){
        listItem = this.listOfProjets;
      } else if (this.filteringName == 'tags') {
        listItem = this.listOfTags
      }
      return listItem;
    },

    showDropDown: function() {
      if (this.showCategoriesDropDown || this.showLocalitesDropDown 
      || this.showTagsDropDown || this.showProjetsDropDown) {
        return true ;
      }
      return false ;
    }
  }, 


  created() {
    this.$store.watch((state, getters) => getters.categories, () => {
      this.getFilteredArticles();
    });
    this.$store.watch((state, getters) => getters.localites, () => {
      this.getFilteredArticles();
    });
    this.$store.watch((state, getters) => getters.projets, () => {
      this.getFilteredArticles();
    });
    this.$store.watch((state, getters) => getters.tags, () => {
      this.getFilteredArticles();
    })
    this.fetchArticlesAndFilters();
  }
};
</script>

<style scoped>
.podcasts__list {
  margin-top:5em;
}

.podcast__card{
  display:flex;
  position:relative;
  align-items: top;
  padding:0em 2em;
  max-width: 857px;
  min-width: 700px;
  height: 20em;
  margin:0em auto 2em auto;
  padding:0 auto 2em auto;
  border-bottom: 2px solid #DFE0E2;
  list-style: none;
}

.card-container {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding:1em;
  width:100%;
}

.FilterTags__clickArea {
  cursor: pointer;
}

</style>
