<template>
  <div class="ProgrammationCard__container">
      <SquarePicture class="ProgrammationCard__square-picture" v-bind:picture="item.show.vignette" />
      <div class="ProgrammationCard__information">
        <h2 class="ProgrammationCard__titre">{{item.show.titre}}</h2>
        <div class="ProgrammationCard__auteur"> Par <span v-if="item.show.artist" class="auteur">{{item.show.artist.nom}}</span>
        <span v-else class="auteur">Raptz</span></div>
        <div class="ProgrammationCard__tags">
          <div class="ProgrammationCard__tag" v-for="style in item.show.musique_styles" :key="style.id">
            <div class="">{{style.nom}}</div>
          </div>
          <div v-if="item.categories">
            <div class="ProgrammationCard__tag" v-for="categorie in item.categories" :key="categorie.id">
              <div class="">{{categorie.nom}}</div>
            </div>
          </div>
        </div>
        <div class="ProgrammationCard__horaire">Tous les <b>{{getDay(item.jour)}}</b> de <b>{{getHoraire(item.heure_debut)}}</b> à <b>{{getHoraire(item.heure_fin)}}</b></div>
      </div>
  </div>
</template>

<style scoped>

.ProgrammationCard__container {
  width: 82.7em;
  height: 22em;
  padding-bottom: 4em;
  color: black;
  display:flex;
  border-bottom: 2px solid #DFE0E2;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-family: Noto Sans;
}


.ProgrammationCard__square-picture {
  width:18em;
  height:18em;
}


.ProgrammationCard__information  {
  margin: 0 1.5em 0 1.5em;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width:58em;
}

h2.ProgrammationCard__titre {
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2em;
  color:#1F1F1F;
}

.ProgrammationCard__auteur {
  margin-top:1em;
  font-size: 1.6em;
  text-align: left;
  color:#85878A;
}

.auteur {
  color:#1A202A;
  text-transform:uppercase;
}

.ProgrammationCard__tags {
  margin-top:0em;
  font-size: 1.6em;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ProgrammationCard__tag {
  margin: 0 0.5em 0 0em;
  padding:0.5em;
  color: #657388;
  font-size: 1.4em;
  border-radius:10em;
  background-image: url("./../../assets/images/icone/item-background-grey.svg");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: contain;
  font-family: Noto Sans;
}

.ProgrammationCard__horaire {
  font-size:1.4em;
} 

</style>

<script>
/**
 * 
 * 
 */
import SquarePicture from '@/components/images/SquarePicture';

export default {
  name: 'ProgrammeCard',
  props: {
      item : Object,
  },
  computed: {
    getDay: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }
      return day => dictDay[day];
    }
  },

  methods : {
    getHoraire:function(horaire){
      return horaire.substring(0,5);
    }
  },
  components: {
    SquarePicture
    }
}
</script>

