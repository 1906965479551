<template>
  <div class="card-container" :style="style">
    <div class="card__image-container">
      <img class="card__image" :src="pictureURL" />
      <img class="card__separator" :src="getSeparator" />
    </div>

    <div class="card__text-box">
        <div class="card__categories-container" v-if="categories">
          <div class="card__tags" v-for="categorie in slicedCategories" :key="categorie.id">#{{categorie.nom}}</div>
        </div>

        <h3 class="card__titre">{{titre}}</h3>
        <p class="card__texte">{{sliceDescription}}</p>
        <div class="main__introduction vertical__card-introduction color__white" v-if="!isMain">Lire la suite...</div>
        <div class="main__introduction vertical__card-introduction color__white" v-if="isMain">En savoir plus..</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerticalCard',
  props:{
    color: String,
    imageNom: String,
    titre: String,
    courteDescription: String,
    categories: Array,
    isMain: Boolean
  },
  
  computed: {
    style(){
      return 'background-color:' + this.color;
    },
    getSeparator() {
      if (this.color == "#FA563F") {
        return require(`@/assets/images/home/cards/home-2ndcard-cut--orange.svg`);
      } else if (this.color == "#F42740") {
        return require(`@/assets/images/home/cards/home-maincard-cut--right-red-horiztontal.svg`);
      }else {
        return require(`@/assets/images/home/cards/home-3rd-card-cut--blue.svg`)
      } 
    },
    pictureURL(){
      if (this.imageNom.includes('http')){
        return this.imageNom;
      } else {
        return require(`@/assets/images/temp/${this.imageNom}`);
      }
    },
    slicedCategories(){
      return this.categories.slice(0,3);
    },
    sliceDescription(){
      let threshold = 100 ;
      if(this.courteDescription != null) {
        if(this.courteDescription.length > threshold) {
          return this.courteDescription.slice(0,threshold) + '...';
        }
      }
      return this.courteDescription;
   },
   sliceTexte:function(texte) {
      let threshold = 100 ;
      if(texte != null) {
        if(texte.length > threshold) {
          return texte.slice(0,threshold) + '...';
        }
      }
   },
  }
};

</script>


<style scoped>

  .card-container {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    height:100%;
    align-items: center;
    border-radius:1.5em;
    height:500px;
  }

  .card__image-container {
    width:100%;
    height:250px;
    border:none;
    position:relative;
  }

  .card__image {
    width:100%;
    max-height:250px;
    border-radius: 1em;
    object-fit: cover;
  }

  .card__separator {
    position:absolute;
    width:100%;
    bottom:-1px;
    left:0;
  }

  .card__tags {
    text-transform:uppercase;
    text-align: left;
    font-family: Noto Sans;
    font-style: normal;
    font-size: 1.3em;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #FFFFFF;
    float:left;
    margin-right:1em;
  }

  .card__text-box {
    width:100%;
    height:250px;
    position:relative;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    text-align:left;
    padding:1em;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card__titre {
    color:white;
    font-style: normal;
    font-weight: normal;
    text-align: left;

  }

  .card__texte {
    font-family: Noto Sans;
    font-size: 16px;
    color: #FFFFFF;
  }

  .icon-and-text-row-container {
    position:absolute;
    right:0;
    bottom:0;
    margin:1.5em 1.5em;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    color:white;
  }

  .icon-and-text-row-container img {
    margin-right:1em;
  }

  .card__icone-texte {
    font-family: Chau Philomene One;
    font-size: 15px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  .vertical__card-introduction {
    width:auto;
    text-transform: uppercase ;
    text-decoration:underline;
  } 

@media screen and (min-width: 800px ) {

  .card-container {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius:1.5em;
    height:100%;
  }

  .card__image-container {
    width:100%;
    height:50%;
    border:none;
    position:relative;
  }

  .card__image {
    height:100%;
    border-radius: 1em;
    object-fit: cover;
  }

  .card__text-box {
    height:50%;
    width:100%;
    position:relative;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    padding:2em;
    text-align: left;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card__titre {
    color:white;
    font-style: normal;
    font-weight: normal;
    text-align: left;
  }

  .card__texte {
    font-family: Noto Sans;
    font-size: 16px;
    color: #FFFFFF;
  }

  .vertical__card-introduction {
    font-size: 1.8em;
    width:auto;
    text-transform: uppercase ;
    text-decoration:underline;
  } 

}
  
</style>
