<template>
    <div class="LargeButton">
        <div class="LargeButton__container">
            <div class="LargeButton__icon-box">
                <div class="LargeButton__texte">{{texte}}</div>
                <div class="LargeButton__icon-button-container">
                    <svg class="icon-button" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Group 2" fill="currentColor">
                            <path id="Vector 2"
                                d="M6.27701 25.6315C7.46606 24.4286 8.59693 23.7009 9.3541 23.7009H11.6517L14.9472 23.1254L17.6722 24.1278L18.9108 27.1538C18.7456 28.4068 19.4062 31.0429 19.4062 32.0454C19.4062 33.0478 18.9108 35.4055 18.9108 35.9067L17.6722 38.3757L15.6904 38.6635L13.6622 38.9512H11.9281L9.74511 38.6635L8.01107 37.661L7.34368 35.9067L6.27701 32.0454V29.1401C5.90766 28.017 5.90767 26.0052 6.27701 25.6315Z"
                                fill="currentColor" />
                            <path id="Vector 2.1"
                                d="M26.5427 25.3605C27.642 24.1927 29.2094 23.5878 29.6674 23.5878H31.3585H33.8777L36.3969 24.561C36.3969 24.561 38 26.571 38 28.4536V33.2832C38 34.2563 37.542 35.5091 37.542 35.9957L36.4069 37.8339L34.5483 38.9512H32.9451L31.3585 38.6719H29.0683L27.4652 37.6987L26.5827 35.9957L26.022 34.224L25.8621 29.4268C25.8621 28.8463 26.1584 25.7688 26.5427 25.3605Z"
                                fill="currentColor" />
                            <path id="Vector 3"
                                d="M25.1155 6.95117L31.9195 10.2835L35.5967 16.184V18.7442L36.9753 19.9946V22.8781C36.9753 25.3234 36.6222 27.2032 36.286 28.2671V30.1721C36.286 31.4309 35.2627 32.4513 34.0003 32.4513C32.7379 32.4513 31.7146 31.4309 31.7146 30.1721V27.4283L31.887 27.0112C31.887 27.0112 31.89 27.0038 31.8958 26.988C31.9019 26.9711 31.9101 26.9473 31.9202 26.9161C31.9404 26.8534 31.9657 26.7684 31.9941 26.6605C32.0509 26.4444 32.116 26.1533 32.1774 25.7911C32.3 25.0671 32.4039 24.0827 32.4039 22.8781V22.0104L31.0253 20.76V17.485L28.7282 13.7991L24.4376 11.6977C22.9097 11.9836 20.0333 12.577 16.8444 13.4033C16.1018 13.5958 15.6803 13.9173 15.3827 14.283C15.0504 14.6912 14.7826 15.2689 14.5564 16.0832C14.3733 16.7423 14.2515 17.4135 14.1129 18.1776C14.0746 18.3887 14.035 18.6069 13.9925 18.834C13.8146 19.7833 13.5747 20.9492 13.0904 21.974C12.7925 22.6045 12.6425 23.8501 12.7198 25.421C12.7931 26.9127 13.0465 28.2792 13.2199 28.8821C13.5679 30.0921 12.8663 31.3543 11.6529 31.7013C10.4395 32.0483 9.17364 31.3488 8.82561 30.1388C8.53949 29.144 8.24134 27.4236 8.15385 25.6442C8.07025 23.9441 8.15007 21.7346 8.95506 20.031C9.16016 19.597 9.31756 18.9641 9.49877 17.9969C9.52978 17.8314 9.56168 17.6548 9.59509 17.4697C9.7369 16.6844 9.90604 15.7477 10.1509 14.8664C10.4609 13.7504 10.9447 12.502 11.8331 11.4104C12.7563 10.2763 14.0246 9.42427 15.6946 8.9915C19.6201 7.97426 23.0998 7.29395 24.3869 7.07507L25.1155 6.95117Z"
                                fill="currentColor" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LargeButton',
  props: {
      texte : String,
  },
}
</script>

<style scoped>

.LargeButton {
    width:100%;
    color: hsl(353, 90%, 55%);
    font-family: Chau Philomene One;
}

.LargeButton__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: .02em;
    height: 7em;
    width:100%;
    padding: 1.2em;
    border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    border:  hsl(353, 90%, 55%) 3px solid;
    overflow: hidden;
    background-color: white;
    box-shadow:  hsl(353, 90%, 55%) 2px 2px 0px;
    transition-property: color, background-color, transform, box-shadow;   
    transition-duration: .4s;
    -moz-transition-duration: .4s;
    transition-timing-function: ease-in; 
    -moz-transition-timing-function: ease-in;
}

.LargeButton__container:hover {
    color: white;
    background-color:  hsl(353, 90%, 55%);
    /* border: var(--color-primary) 2px solid; */
    transform: translate(2px, 2px);
    box-shadow:  hsl(353, 90%, 55%) 0px 0px 0px;
   -webkit-transform: translate(2px, 2px);
    -moz-transform: translate(2px, 2px);
    -ms-transform: translate(2px, 2px);
    -o-transform: translate(2px, 2px); 
}

.LargeButton__icon-box {
    display: flex;
    align-items: center;
}

.LargeButton__texte {
  font-size: 2.6em;
  text-transform: uppercase;
  text-align:center;
  z-index:0;
}

.LargeButton__icon-button-container {
    width:5em;
    margin-left: .2em;
}


</style>