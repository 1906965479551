<template>
  <div class="content">
    <div class="shaped__backgrounds">
      <div class="page" v-if="show"> 
        <ShowTitle :plannings="plannings" :show="show" />
        <ContentTexte :content="show"/>
        <img class="separator" src="./../assets/images/home/scratch-light-hero-dark--1900w.svg"/>
        <section class="ShowDetail__relatedPodcast">
          <RelatedPodcasts  v-if="podcasts.length>0" :items="podcasts" titre="Derniers podcasts" 
            circleColor="light-blue"
            type="podcasts"
            backgroundColor="#233045" 
            texteColor="white"/>
        </section>
        <router-link :to="{ name:'show-podcasts', params: {slug: this.slug}}"> 
          <div  class="LargeButton__container">
            <LargeButton :texte="getShowName()"/>
          </div>
        </router-link>
      </div>
      <div v-else>Loading....</div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import axios from 'axios';
import ShowTitle from '@/components/sections/ShowTitle.vue';
import ContentTexte from '@/components/sections/ContentTexte.vue';
import RelatedPodcasts from '@/components/sections/RelatedPodcasts.vue';
import LargeButton from '@/components/buttons/LargeButton';

export default {
  name:"Show",
  components:{
    ShowTitle,
    ContentTexte,
    RelatedPodcasts,
    LargeButton
  },
  data() {
    return{
      show : null,
      style: null,
      podcasts: [],
      plannings: [],
      isAnSrc: true
    }
  },
  props : {
    slug: {
      type: String,
      required: true
    },
  },
  methods : {
    fetchShowPlanningPodcasts() {
      let urlShow = 'show/';
      urlShow =  urlShow + this.slug ;

      let urlPodcasts = urlShow + '/podcasts/';
      let urlRelatedPlanning = 'planning/' + this.slug ;

      const requestedShow = api.get(urlShow);
      const requestedPodcasts = api.get(urlPodcasts);
      const requestedRelatedPlanning = api.get(urlRelatedPlanning);

      return axios.all([requestedShow, requestedPodcasts, requestedRelatedPlanning])
        .then(axios.spread((...responses) => {
          this.show = responses[0].data;
           if (this.show.image != null) {
            this.style = 'background-image: url(' + this.show.image + ');';
            } else {
            this.style = 'background-color: #DDDDDD;';
            }

          this.podcasts = responses[1].data['results'].slice(0,5);
          this.plannings = responses[2].data['results'];
        })).catch(errors => {
          console.error(errors);
          return this.$router.push({
            name: 'error'
          });
        })
    },
    
    /**
     * @description wrapper for utils function
     */
    getShowName(){
      return this.show.titre;
    }
  },
  created() {
    this.fetchShowPlanningPodcasts();
  }
}
</script>

<style scoped>

.content {
  padding-top:19em;
  font-style: normal;
  font-weight: normal;
}

.separator {
  position:relative;
  top:4px;
}

.ShowDetail__relatedPodcast {
  position:relative;
  background-color:#233045;
  padding-bottom:5em;
  margin-bottom:5em;
}

.podcast__all {
    width:30em;
    margin:0 auto;
    padding:1em;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icone {
    height:5em;
}

.icone__texte {  
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8em;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: white;
  margin-left:1em;
}

.LargeButton__container {
  width:35em;
  margin: 0 auto 10em auto;
}


</style>

