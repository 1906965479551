<template>
  <section class="section__container">
    <div class="section__texte"> 
      <h2 class="section__titre">{{articleTitre}}</h2>
      <div class="section__sous-titre"> Publié par <span class="bold">{{articleAuteur}}</span> </div>
      <div class="section__date-container">
          <img class="navigation__icone " src='./../../assets/images/icone/icon-date--black.svg'/>
          <div class="section__date">Le {{eventDay}} {{eventMonth}} {{eventYear}}</div>
      </div>
    </div>
    <img class="section__image" v-bind:src="pictureURL" alt="Raptz image"/>
  </section>
</template>
<style scoped>

.section__container {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  padding-left:1em;
  padding-right:1em;
  margin-bottom:5em;
}

.section__image {
  max-width: 92em;
  height:30em;
  object-fit:contain;
  border-radius:0.8em;
  margin-top:20px;
}

.section__texte {
  height:100%;
  width:auto;
  display:flex;
  flex-direction:column;
  align-items: center;
}

.section__titre {
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 4.0em;
  line-height: 130%;
  text-align:center;

/* or 55px */
letter-spacing: 0.01em;

/* darker-grey */
color: #233045;
}

.section__sous-titre {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
}

.section__date-container {
  display:flex;
  margin-top:1em;
  align-items: center;
}

.section__date {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 150%;
  margin-left:1em;
}

.section__texte {
  font-size: 1.8em;
  line-height: 155%;

}

.bold {
  font-weight:bold !important;
}

</style>
<script>
export default {
  name:'ArticleTitle',
  props: {
      articleImage : String,
      articleTitre : String,
      articleDate : String,
      articleAuteur : String,
  },
  data() {
    return{
      month: {
        '01':"Janvier",
        '02':"Février",
        '03':"Mars",
        '04':"Avril",
        '05':"Mai",
        '06':"Juin",
        '07':"Juillet",
        '08':"Août",
        '09':"Septembre",
        '10':"Octobre",
        '11':"Novembre",
        '12':"Décembre"
      }
    }
  },
    
  computed: {
    pictureURL(){
      if (this.articleImage.includes('http')){
        return this.articleImage;
      } else {
        return require(`@/assets/images/temp/${this.articleImage}`);
      }
    },
    eventDay: function(){
      let day =  this.articleDate.substring(8,10) ;
      return day;
    },
    eventMonth:function(){
      let month =  this.articleDate.substring(5,7) ;
      return this.month[month];
    },
    eventYear:function(){
      let year = this.articleDate.substring(0,4);
      return year;
    }
  },
  
}
</script>

