/**
 * @description utils class to be used in multiples components
 */


/**
 * 
 * @param {Object} podcast 
 * @returns {String}
 */
export function getPodcastName(podcast){
    let auteur = null;
    if(podcast.show != null && podcast.show.artist != null){
        auteur = podcast.show.artist.alias != null ? podcast.show.artist.alias : null;
    } else if (podcast.article !=null && podcast.article.auteur != null){
        auteur = podcast.show.auteur.nom != null;
    } else if (podcast.artist != null || podcast.auteur !=null){
        auteur = podcast.artist != null ? podcast.artist.alias : podcast.auteur.alias;
    }
    auteur = auteur != null ? auteur : 'Raptz' ;
    return auteur;
}

/**
 * 
 * @param {Object} show 
 * @returns {String}
 */
 export function getShowName(show){
    let artistAlias = null;
    if(show != null && show.artist != null){
        artistAlias = show.artist.alias != null ? show.artist.alias : null;
    } else {
        artistAlias = show.titre;
    }
    return artistAlias;
}

/**
 * @param {String} slug
 * @returns {string} unslug string
 */
export function unslugString(slug) {
    const titleUnslug = slug.split('-');
    titleUnslug.forEach( (element, index) => {
        titleUnslug[index] = element[0].toUpperCase() + element.substring(1)
    });
    return titleUnslug.join(' ');
}