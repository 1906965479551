<template>
  <div class="MessageCard">
    <a :href="link">
      <div class="card__message">
        <div class="card__texte">
          <img class="card__separator" :src="getSeparator" />
          <h3 class="card__titre">{{message.titre}}</h3>
          <div class="card__description" v-html="sliceDescription"></div>
      </div>
      <img class="card__image" :src="message.image">
    </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MessageCard',
  props: {
    message: Object
  },
  data() {
    return {
      link: null
    }
  },
  computed: {
    getSeparator() {
        return require(`@/assets/images/home/cards/home-3rd-card-cut--blue.svg`)
    },
    sliceDescription(){
      let threshold = 350 ;
      if(this.message.description != null) {
        if(this.message.description.length > threshold) {
         return this.message.description .slice(0,threshold) + '...';
        }
      }
      return this.message.description ; 
    }
  },
  created() {
    this.link = this.message.lien;
  }
}
</script>


<style scoped>
/***** Description Style *****/
.card__description >>> p {
  text-align:left;
  margin:1em;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  }


.card__description >>> h2 {
  margin-top:1em;
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
}

.card__description >>> h3 {
  margin-top:1em;
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
}

.card__description >>>img.full-width{
  width:100%;
}

.card__description >>>img.left{
  text-align:left;
}

.card__description >>>img.right{
  text-align:right;
}

.card__description >>> a {
  color:#993366;
  font-weight:bold;
}
/**********/

.card__message:hover > .card__texte {
  transform: translateY(-10em);
}


.card__message {
  position:relative;
  display:flex;
  padding-bottom:12em;
  max-height:auto;
  max-width:380px;
  flex-direction: column;
  justify-content: flex-start;
  align-items:left;
  padding-top:0;
  z-index:0;
  overflow :hidden;
  border-radius: 0px 0px 10px 10px;
}

.card__separator {
  position:absolute;
  width:100%;
  top:-29px;
  left:0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.card__texte {
  color:white;
  background-color:#3A4D6C;
  position:absolute;
  width:100%;
  height:27em;
  bottom:-10em;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: left;
  z-index:100;
  transition: transform 1s;
}

.card__titre {
  font-size:2em;
}

.card__image{
  position:absolute;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  position:relative;
  z-index:0;
}

</style>

