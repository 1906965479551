<template>
  <div class="CardListContainer">
    <div v-if="type=='podcast'">
      <ul>
        <transition-group name="fade" mode="out-in">
          <li class="item__card" v-for="item in items" :key="item.id">
            <router-link :to="{ name:'podcast-detail', params: {slug: item.slug}}">
              <PodcastCard class="card-container" :podcast="item" />    
            </router-link>
          </li>
        </transition-group>
      </ul>
    </div>
    <div v-else-if="type=='shows'">
      <ul>
        <transition-group name="fade" mode="out-in">
          <li class="item__card" v-for="item in items" :key="item.slug">
            <router-link :to="{  name:'show-detail', params: {slug: item.slug}}">
              <ArticleCard class="card-container" :item="item" />    
            </router-link>
          </li>
        </transition-group>
      </ul>
    </div>
    <div v-else-if="type=='articles'">
      <ul>
        <transition-group name="fade" mode="out-in">
          <li class="item__card" v-for="item in items" :key="item.id">
            <router-link :to="{ name:'article-detail', params: {slug: item.slug}}">
              <ArticleCard class="card-container" :item="item" />    
            </router-link>
          </li>
        </transition-group>
      </ul>
    </div>
  </div>
</template>

<script>
import PodcastCard from '@/components/cards/PodcastCard';
import ArticleCard from '@/components/cards/ArticleCard';
import ShowCard from '@/components/cards/ArticleCard';

export default {
  name: 'CardListContainer',
  components: {
    PodcastCard,
    ArticleCard,
    ShowCard
  },
  props : {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
    }
  },
}
</script>

<style scoped>

.item__card{
  display:flex;
  position:relative;
  align-items: top;
  padding:0em 2em;
  max-width: 857px;
  min-width: 700px;
  height: 20em;
  margin:0em auto 2em auto;
  padding:0 auto 2em auto;
  border-bottom: 2px solid #DFE0E2;
  list-style: none;
}



.card-container {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding:1em;
  width:100%;
}

</style>