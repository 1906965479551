<template>
  <div class="content">
    <div class="page" v-if="roadshow"> 
      <h1>{{roadshow.nom}}</h1>
      <div class="Roadshow__informations">Retrouvez-nous à {{roadshow.lieu}} 
        <span v-if="roadshow.heure_debut">à partir de {{getHours(roadshow.heure_debut)}}</span> 
        <span v-if="roadshow.heure_fin"> jusqu'à {{getHours(roadshow.heure_fin)}}</span>
      </div>
      <ContentTexte :content="roadshow"/>
      <img class="separator" src="./../assets/images/home/scratch-light-hero-dark--1900w.svg"/>
      <section class="ShowDetail__relatedPodcast">
        <RelatedPodcasts  v-if="podcasts.length>0" :items="podcasts" titre="Derniers podcasts" 
          circleColor="blue"
          type="podcasts"
          backgroundColor="#233045" 
          texteColor="white"/>
      </section>
    </div>
    <div v-else>Loading....</div>
  </div>
</template>

<script>
import api from '@/services/api';
import axios from 'axios';

import ContentTexte from '@/components/sections/ContentTexte.vue';
import RelatedPodcasts from '@/components/sections/RelatedPodcasts.vue';

export default {
  name:"RoadShowDetail",
  components:{
    ContentTexte,
    RelatedPodcasts
  },
  data() {
    return{
      roadshow : null,
      podcasts : []
    }
  },
  props : {
    slug: {
      type:String,
      required: true
    }
  },
  methods: {
    fetchRoadShowAndPodcasts: function() {
      let urlRoadShow = 'roadshow/' +  this.slug ;
      let urlLatestPodcasts =  'podcasts/latest' ;

      const requestedRoadShow = api.get(urlRoadShow);
      const requestedLatestPodcasts = api.get(urlLatestPodcasts);

      return axios.all([requestedRoadShow, requestedLatestPodcasts])
        .then(axios.spread((...responses) => {
          this.roadshow = responses[0].data;
          this.podcasts = responses[1].data['results'].slice(0,5);
        })).catch(errors => {
          console.error(errors);
          return this.$router.push({
            name: 'error'
          });
        });
    },
  },
  computed: {
    getHours: function(){
      return (hour) => hour.substring(0,5);
    }
  },
  created() {
    this.fetchRoadShowAndPodcasts()
  }
}
</script>

<style scoped>

.content {
  padding-top:19em;
  font-style: normal;
  font-weight: normal;
}

.separator {
  position:relative;
  top:4px;
}

.ShowDetail__relatedPodcast {
  position:relative;
  background-color:#233045;
  padding-bottom:10em;
}

.podcast__all {
    width:30em;
    margin:0 auto;
    padding:1em;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icone {
    height:5em;
}

.icone__texte {  
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8em;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: white;
  margin-left:1em;
}
.Roadshow__informations {
  margin:1em;
  font-size:1.6em;
}
</style>

