<template>
  <div class="content header__top-padding">
    <MainHero class="main-hero" v-bind:hero="hero"/>
    <div class="show__container">
      <div class="show__list-container">
        <div class="show__item" v-for="show in shows" :key="show.id">
          <router-link :to="{  name:'show-detail', params: {slug: show.slug}}">
            <ShowCard :item="show" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .show__container {
    max-width: 117em;
    margin:10em auto 10em auto;
  }
  
  .show__item {
    margin:2em;
  }

  .show__list-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

  }
  h2{
    padding:20em;
  }
</style>

<script>
import api from '@/services/api'
import MainHero from '@/components/hero/MainHero';
import ShowCard from '@/components/cards/ShowCard';
import axios from 'axios';


export default {
  name: 'Shows',

  data() {
    return {
      allShow: null,
      shows : null,
      hero: {
        imageHD : "images/programme/hero-la-prog-v2.jpg",
        imageM : "images/programme/hero-la-prog-v2.jpg",
        imageLD : "images/programme/hero-la-prog--s.jpg",
        titre : "SHOWS",
        filtre: "red"
      }
    }
  },
  methods : {
    fetchShow() {
      return api.get(`shows/`).then(response => this.shows = response.data.results);
    }
  },
  created() {
    this.fetchShow();
  },
  components: {
    MainHero,
    ShowCard
  },
};

</script>
