<template>
    <div class="ShowCard__picture-container">
        <h2 class="ShowCard__texte">{{item.titre}}</h2>
        <SquarePicture v-bind:picture="image" />

    </div>
</template>

<script>
import SquarePicture from '@/components/images/SquarePicture';

export default {
  name: 'ShowCard',
  props: {
      item : Object,
  },

  data() {
    return {
        hovered: false
      }
  },
  computed:{
      image:function(){
          return this.item.vignette !==null ? this.item.vignette : this.item.image 
      }
  },
  components: {
    SquarePicture
    }
}
</script>

<style scoped>

    .ShowCard__picture-container {
        width:22em;
        height:22em;
        position:relative;
    }

    .ShowCard__picture-container:hover > h2.ShowCard__texte {
        visibility: visible;
    }

    .ShowCard__texte {
        color:white;
        letter-spacing: 0.5px;
        font-size:2em;
        background-color:rgb(12, 4, 4, 0.5);
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        visibility: hidden;
        display:flex;
        justify-content: center;
        align-items: center;
    }

</style>