<template>
    <div v-if="size=='small'" class="image__container-small">
        <img class="card__round-image-small" :src="pictureURL">
        <img class="image__background-small" :src="backgroundURL">
    </div>
    <div v-else-if="size=='medium'" class="image__container-medium">
        <img class="card__round-image-medium" :src="pictureURL">
        <img class="image__background-medium" :src="backgroundURL">
    </div>
    <div v-else class="image__container-big">
      <img class="card__round-image-big" :src="pictureURL">
      <img class="image__background-big" :src="backgroundURL">
    </div>
</template>

<style scoped>
  .image__container-small {
    width:100%;
    height:auto;
    margin:auto;
    position:relative;
  }

  .image__background-small {
    position:absolute;
    width:105%;
    top:-1.5em;
    left:-1.5em;
    object-fit: cover;
    z-index:0;
  }

  .card__round-image-small {
    position:absolute;
    width:15em;
    height:15em;
    top:0em;
    left:0em;
    border-radius:50%;
    object-fit: cover;
    z-index:10;
  } 

  .image__container-medium {
    width:100%;
    height:auto;
    margin:auto;
    position:relative;
  }

  .image__background-medium {
    position:absolute;
    width:105%;
    top:-1.5em;
    left:-1.5em;
    object-fit: cover;
    z-index:0;
  }

  .card__round-image-medium {
    position:absolute;
    width:18em;
    height:18em;
    top:0em;
    left:0em;
    border-radius:50%;
    object-fit: cover;
    z-index:10;
  } 

  .image__container-big {
    width:100%;
    height:auto;
    margin:auto;
    position:relative;
  }

  .image__background-big {
    position:absolute;
    width:26em;
    top:-1.5em;
    left:-1.5em;
    object-fit: cover;
    z-index:0;
  }

  .card__round-image-big {
    position:absolute;
    width:25em;
    height:25em;
    top:0em;
    left:0em;
    border-radius:50%;
    object-fit: cover;
    z-index:10;
  } 

</style>

<script>
export default {
  name:'RoundPicture',
  props : {
    picture: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    size : {
      type: String
    }
  },
  computed : {
    pictureURL(){
        return this.picture;
      }, 
    backgroundURL(){
      if (this.backgroundColor == 'blue') {
        return require(`@/assets/images/icone/round-vector-blue.svg`);
      } else if (this.backgroundColor == 'gris'){
        return require(`@/assets/images/icone/round-vector-grey.svg`);
      } else if (this.backgroundColor == 'rouge') {
        return require(`@/assets/images/icone/round-vector-red.svg`);
      } else if (this.backgroundColor == 'orange') {
        return require(`@/assets/images/icone/round-vector-orange.svg`);
      } else if (this.backgroundColor == 'light-blue'){
        return require(`@/assets/images/icone/round-vector-light-blue.svg`);
      }else {
        return require(`@/assets/images/icone/round-vector-beige.svg`);
      }
    }
  }
}
</script>

