<template>
  <main class="main__background-container">
    <img class="main__right-separateur" src="./../../assets/images/home/home-illu-firstSec--right@2x.png">   
    <img class="main__left-separateur" src="./../../assets/images/home/home-illu-firstSec--left@2x.png">
    <div class="main__container margin-wrapper">
      <div class="main__row-container-top">
        <router-link :to="{ name:'podcast-detail', params: {slug: lastPodcast.slug}}">
          <div class="main__last-podcast" >
            <LastPodcastCard color="#3A4D6C" v-bind:imageNom="lastPodcastVignette" texte="" v-bind:nomDeGroupe="lastPodcastTitre" v-if="lastPodcast" />
          </div>
        </router-link>
          <mq-layout class="main__main-card" mq="mobile">
            <router-link :to="{name:mainCardType, params:{ slug:mainCard.slug}}">
              <VerticalCard class="main__societe" 
                color="#F42740" 
                :imageNom="mainCard.vignette" 
                :titre="mainCard.titre" 
                :courteDescription="mainCard.courte_description" 
                isMain="true"/>
            </router-link>
          </mq-layout>            
        <mq-layout class="main__main-card" mq="tablet+">
            <router-link :to="{name:mainCardType, params:{ slug:mainCard.slug}}">
            <HorizontalCard class="main__main-card" color="#F42740" :imageNom="mainCard.image" :titre="mainCard.titre" :courteDescription="mainCard.courte_description"/>
            </router-link>
        </mq-layout>
      </div>
      <div class="main__row-container">
        <div class="main__column-container">
            <ZemixCard v-if="publishDefaultZemix" class="main__ze-mix"/>
            <MessageCard v-else :message="message" class="main__ze-mix"/>
          <router-link v-bind:to="'/prog'"  >
            <div class="main__programmation-button">
              <LargeButton texte="Programmation"/>
            </div>
          </router-link>
        </div>
 
          <div v-if="cardOrange.post_type == 'Article'" class="main__vertical-card">
            <router-link :to="{name:'article-detail', params:{ slug:cardOrange.slug}}">
            <VerticalCard  class="main__societe" color="#FA563F" 
              :imageNom="checkImageOrVignette( cardOrange.image, cardOrange.vignette)"
              :courteDescription="cardOrange.courte_description"
              :titre="cardOrange.titre"
              :categories="checkCategoriesOrStyles(cardOrange)"/>
            </router-link>
          </div>
          <div v-else class="main__vertical-card">
            <router-link :to="{name:'podcast-detail', params:{ slug:cardOrange.slug}}">
            <VerticalCard  class="main__societe" color="#FA563F" 
              :imageNom="checkImageOrVignette( cardOrange.image, cardOrange.vignette)"
              :courteDescription="cardOrange.courte_description"
              :titre="cardOrange.titre"
              :categories="checkCategoriesOrStyles(cardOrange)"/>
            </router-link>
          </div>

          <div v-if="cardBlue.post_type == 'Article'" class="main__vertical-card">
            <router-link :to="{name:'article-detail', params:{ slug:cardBlue.slug}}">
              <VerticalCard class="main__societe" color="#3A4D6C" 
                :imageNom="checkImageOrVignette( cardBlue.image, cardBlue.vignette)"
                :courteDescription="cardBlue.courte_description"
                :titre="cardBlue.titre"
                :categories="checkCategoriesOrStyles(cardBlue)"/>
            </router-link>
          </div>
          <div v-else class="main__vertical-card">
            <router-link :to="{name:'podcast-detail', params:{ slug:cardBlue.slug}}">
              <VerticalCard class="main__societe" color="#3A4D6C" 
                :imageNom="checkImageOrVignette( cardBlue.image, cardBlue.vignette)"
                :courteDescription="cardBlue.courte_description"
                :titre="cardBlue.titre"
                :categories="checkCategoriesOrStyles(cardBlue)"/>
            </router-link>
          </div>
      </div>
    </div>

  </main>


</template>

<script>
import VerticalCard from '@/components/cards/Vertical';
import HorizontalCard from '@/components/cards/Horizontal';
import LastPodcastCard from '@/components/cards/LastPodcastCard';
import ZemixCard from '@/components/cards/ZemixCard';
import MessageCard from '@/components/cards/MessageCard';
import LargeButton from '@/components/buttons/LargeButton';

export default {
  name:"Main",
  props: {
    lastPodcast: Object,
    mainCard: Object,
    mainCardType: String,
    message: Object,
    cardOrange: Object,
    cardBlue: Object
  },
  computed:{
    lastPodcastVignette(){
      return this.lastPodcast.vignette == null ? this.lastPodcast.show.vignette: this.lastPodcast.vignette
    },
    lastPodcastTitre(){
      return  this.lastPodcast.titre == '' ? this.lastPodcast.show.name : this.lastPodcast.titre;
    },
    publishDefaultZemix(){
      if (this.message.publication === false){
        return true;
      }
      return false;
    }
  },
  methods:{
    checkImageOrVignette(image, vignette){
      if(vignette != null){
        return vignette
      }
      return image;
    },
    checkCategoriesOrStyles(object){
      if(object.categories.length > 0){
        return object.categories ;
      } else if (object.musique_styles.length > 0){
        return object.musique_styles;
      } else {
        return object.tags;
      }
    }
  },
  components: {
    LastPodcastCard,
    ZemixCard,
    MessageCard,
    VerticalCard,
    HorizontalCard,
    LargeButton
  }

}
</script>

<style>

@media screen and (max-width: 800px ) {
  .main__row-container-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main__background-container{
    position:relative;
  }

  .main__right-separateur, .main__left-separateur {
    display:none;
  }
  .main__container {
    width:100%;
    padding: 0 4em;
  }

  .main__last-podcast {
    width:100%; 
    height:auto;
  }

  .main__main-card {
    width:100%;
    margin-top:4em;
  }

  .main__ze-mix {
    width:100%;
    margin:10em auto 0 auto;
  }

  .main__programmation-button  {
    width:auto;
    height:auto;
    margin-top:4.2em;
    z-index:2;
  }


  .main__societe {
    width:100%;
    margin-top:4em;
    z-index:2;
  }

  .main__action{
    width:100%;
    margin-top:4em;

    z-index:2;
  }

  .main__row-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
} 

@media screen and (min-width: 800px ) and (max-width: 1270px ) {
.main__background-container{
    position:relative;
  }

  .main__row-container-top {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin-bottom:7.2em;
  }

 .main__right-separateur {
    position:absolute;
    right:0%;
    top: 25%;
    max-width:38%;
  }

  .main__left-separateur {
    position:absolute;
    left:0%;
    bottom: 0vh;
    max-width:39%;
  }

  .main__container {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    /*reset */
    width:100%;
    padding: 0 3.5em;
  }

  .main__row-container {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom:4.2em;
  }

  .main__column-container {
    display: flex;
    flex-direction: column;
  }

  .main__main-card {
    width: 57em;
    height: 34em;
    margin-left:0em;
  }

  .main__last-podcast {
    width:34em; 
  }

  .main__ze-mix {
    margin-top:0em;
    z-index:1;
  }

  .main__programmation-button  {
    z-index:2;
    width:34em;
    margin: 0 auto 0 auto;
  }

  .main__vertical-card {
    height:52em;
    }

  .main__societe {
    width:34em;
    margin-left:4.2em;
  }

  .main__action{
    width:34em;
    margin-left:4.2em;
  }
}

@media screen and (min-width: 1270px ) {
  .main__row-container-top {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin-bottom:7.2em;
  }

  .main__background-container{
    position:relative;
  }

 .main__right-separateur {
    position:absolute;
    right:0%;
    top: 25%;
    max-width:38%;
  }

  .main__left-separateur {
    position:absolute;
    left:0%;
    bottom: 0vh;
    max-width:39%;
  }

  .main__container {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;

    /*reset */
    padding: 0 0em;
  }

  .main__row-container {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom:4.2em;
  }

  .main__column-container {
    display: flex;
    flex-direction: column;
  }

  .main__main-card {
    width: 72em;
    height: 32em;
    margin-left:0em;
  }

  .main__last-podcast {
    width:38em; 
  }

  .main__ze-mix {
    margin-top:0em;
    z-index:1;
  }

  .main__programmation-button {
    width:34em;
    margin: 4.2em auto 0 auto;
  }

  .main__vertical-card {
    height:52em;
    }

  .main__societe {
    width:34em;
    margin-left:4.2em;
  }

  .main__action{
    width:34em;
    margin-left:4.2em;
  }
}
</style>