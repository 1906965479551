<template>
  <div class="content header__top-padding">
    <div class="shaped__backgrounds">
      <div class="search-results">
        <div class="search-results__header">
          <div class="search-results__h-wrapper">
            <h1 class="search-results__title">Recherche</h1>
          </div>
          <div class="search-results__filter">
            <div class="search-results__count-result search-results__h-wrapper">
              <h2 class="search-results__sub-title">Résultat de votre recherche : {{getSearch}} </h2>
            </div>
            <div class="search-results__options">
              <input class="invisible" type="radio" id="shows" value="shows" v-model="selectedType">
              <label class="color-red first-left" for="shows">Shows</label>
              <input class="invisible" type="radio" id="articles" value="articles" v-model="selectedType">
              <label class="color-red" for="articles">Articles</label>
              <input class="invisible" type="radio" id="podcasts" value="podcasts" v-model="selectedType">
              <label class="color-red" for="podcasts">Podcasts</label>
            </div>
          </div>
        </div>
        <div class="search-results__results">
          <div v-if="selectedType=='shows'">
            <CardListContainer type="shows" :items="shows" />
          </div>
          <div v-else-if="selectedType=='articles'">
            <CardListContainer type="articles" :items="articles" /></div>
          <div v-else-if="selectedType=='podcasts'">
            <CardListContainer type="podcast" :items="podcasts" />
          </div>
        </div>
      </div>
    </div>
    <div class="before-footer">
    </div>
  </div>
</template>

<style scoped>
  .content {
    color: #1A202A;
  }

  .search-results {
    margin: 5em auto;
  }

  .search-results__h-wrapper{
    max-width:117em;
    margin: 0em auto;
  }

  .search-results__title {
    text-align:left;
    max-width: 704px;
    height: 61px;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 140%;

    /* or 63px */
    color:var( --main-blue-color);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    margin:1em 0 ;
  }

  .search-results__count-result {
    display:flex; 
    align-items: center;
  
  }


  .search-results__sub-title {
    margin: 0 1.5em;
  }


  .search-results__count {
    margin: 0  0.5em;
    font-family: var(--typo-title);
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  .search-results__filter {
    background: #C4C4C4;
    max-width:100%;
    padding: 3em 2em;
  }

  .search-results__results{
    margin: 4em 0 ;
  }


  .before-footer {
    margin-top:10em;
    width:100%;
    height:41em;
    background: #C4C4C4;
  }

  .search-results__options {
    max-width: 117em;
    margin:0em auto 0em auto;
    padding-left:0em;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  label.first-left {
  }

  label {
    float: left;
    padding:1em;
    border-radius:0px;
    font-size: 2.2em;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color:#76849C;
    margin-right:2em;
    cursor: pointer;
  }

  input.invisible {
    display:none;
  }

  input.all-styles {
    display:none;
  }

  input:checked + label {
    background-image: url("./../assets/images/icone/icon-checked--red.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    color:white;
  }

  input:checked + label::before {
    /*no style*/
  }

</style>

<script>
import api from '@/services/api';
import axios from 'axios';
import CardListContainer from '@/components/sections/CardListContainer'

/**
 * The view received search String from store then transform it to get all searchs
 */
export default {
  name: 'SearchResults',
  components: {
    CardListContainer
  },
  data() {
    return {
      query : "",
      shows : null,
      showsLength : 0,
      podcasts : null,
      podcastsLength : 0,
      articles : null,
      articlesLength :0,
      selectedType : "", 
    }
  },
  computed:{
    getResults() {
        return this.showsLength + this.podcastsLength + this.articlesLength ;

    },
    getSearch() {
      this.query = this.$store.getters.getSearchString ;
      this.query = this.query.split(" ").join(',');
      let urlShow = 'search/shows/?search=' + this.query;
      let urlPodcasts = 'search/podcasts/?search=' + this.query;
      let urlArticles = 'search/articles/?search=' + this.query;

      const requestedShow = api.get(urlShow);
      const requestedPodcasts = api.get(urlPodcasts);
      const requestedArticles = api.get(urlArticles);
      if( this.query.length > 0){
        axios.all([requestedShow, requestedPodcasts, requestedArticles])
          .then(axios.spread((...responses) => {
            this.shows = responses[0].data['results'];
            this.showsLength = responses[0].data['results'].length;
            this.podcasts = responses[1].data['results'];
            this.podcastsLength = responses[1].data['results'].length;
            this.articles = responses[2].data['results'];
            this.articlesLength = responses[2].data['results'].length;
          })).catch(errors => {
            console.log(errors)
          }); 
        if (this.podcastsLength >= this.articlesLength && this.podcastsLength > this.showsLength ) {
          this.selectedType = 'podcasts';
        } else if (this.podcastsLength < this.articlesLength && this.articlesLength >= this.showsLength ) {
          this.selectedType = 'articles';
        } else {
          this.selectedType = "shows";
        }
        return this.showsLength + " Shows, " + this.articlesLength + " articles, " + this.podcastsLength + " podcasts." ; 
      } else {
        return "Aucune recherche lancée"
      }
    }

  }
};

</script>
