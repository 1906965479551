<template>
  <div class="content">
    <div class="shaped__backgrounds">
      <div class="podcast" v-if="podcast"> 
        <PodcastTitle v-bind:podcast="podcast"/>
        <ContentTexte :content="podcast"/>
        <section v-if="podcast.show || podcast.article" class="podcastShowDetail__container">
          <PodcastParentDetail :podcastShow="podcast.show" :podcastArticle="podcast.article" :plannings="plannings" :sameHours="getSameHours"/>
        </section>
        <section v-if="podcast.show || podcast.article"  class="RelatedPodcasts__container">
          <RelatedPodcasts 
            :items="filteredPodcasts" 
            titre="podcasts précédents" 
            type="podcasts"
            circleColor="light-blue"
            backgroundColor="#233045" 
            texteColor="white"/>
        </section>
        <router-link v-if="podcast.show" to="/podcasts">
          <div  class="LargeButton__container">
            <LargeButton texte="tous les podcasts"/>
          </div>
        </router-link>
        <router-link v-if="podcast.article" to="/actions">
          <div  class="LargeButton__container">
            <LargeButton texte="toutes nos actions"/>
          </div>
        </router-link>
      </div>
      <div v-else>Loading....</div>
    </div>
  </div>
</template>

<style scoped>
  .podcast {
    padding-top:19em;
    font-style: normal;
    font-weight: normal;
  }

  .podcastShowDetail__container{
    display:flex;
    justify-content: center;
  }

  .RelatedPodcasts {
    margin-bottom:5em;
  }

  .LargeButton__container {
    width:35em;
    margin: 0 auto 10em auto;
  }

</style>


<script>
import api from '@/services/api';
import axios from 'axios';

import PodcastParentDetail from '@/components/sections/PodcastParentDetail';
import PodcastTitle from '@/components/sections/PodcastTitle';
import ContentTexte from '@/components/sections/ContentTexte';
import RelatedPodcasts from '@/components/sections/RelatedPodcasts';
import LargeButton from '@/components/buttons/LargeButton';

/**
 * 
 */
export default {
  name:"Podcast",
  props : {
    slug: String
  },
  data() {
    return{
      plannings: [],
      podcast: null,
      relatedPodcasts: [],
      filteredPodcasts: [],
      podcastTitre: '',
      podcastVignette: '',
      dictDay : {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }
    }
  },
  computed : {
    getSameHours: function(){
      let isSameHours;
      if(this.plannings.length > 0){
        let arrayOfHours = [];
        for (let planning of this.plannings) {
          arrayOfHours.push(planning.heure_debut);
        }
        //On vérifie pour chaque planning s'il correspond à la même heure
        isSameHours = arrayOfHours.every((val, i, arr) => val === arr[0]);
      }
      return isSameHours;
    }
  },
  methods : {


    /**
     * This method have multiple steps
     *  First it take the selected podcast by id prop
     *  Then having the podcast, it gets the podcast show slug and the podcast article slug if any 
     *  If there is a show 
     *    it makes another call to get related plannings and podcasts to the show
     *    Before showing related podcasts it filter the actual podcast id
     *  If there is no show linked: 
     *    it will look for related article podcasts
     */
    fetchChainedPodcastAndRelated() {
      let urlPodcast = 'podcast/' + this.slug ;

      api.get(urlPodcast).then((response) => {
        this.podcast = response.data;
        if(this.podcast.show) {
          let urlShowPodcasts = 'show/' + this.podcast['show']['slug'] + '/podcasts';
          let urlShowPlannings = 'planning/' + this.podcast['show']['slug'];

          const requestedShowPodcasts = api.get(urlShowPodcasts);
          const requestedRelatedPlanning = api.get(urlShowPlannings);
          return axios.all([requestedShowPodcasts, requestedRelatedPlanning]).then(axios.spread((...responses) => {
            this.relatedPodcasts = responses[0].data['results'];
            this.filteredPodcasts = this.relatedPodcasts.filter( podcast => podcast.slug != this.slug).slice(0,5);
            this.plannings = responses[1].data['results'];
          })).catch(errors => {
            console.warn('following error occur' + errors);
            return this.$router.push({
              name: 'error'
            });
          })
        // If it is not a podcast it must be an article
        } else if (this.podcast.article){
          const urlArticlePodcasts = 'article/' + this.podcast['article']['slug'] + '/podcasts';
          api.get(urlArticlePodcasts).then((response) => {
            this.relatedPodcasts = response.data['results'];
            this.filteredPodcasts = this.relatedPodcasts.filter( podcast => podcast.id != this.id);
          }).catch(errors => {
            console.error(errors);
            return this.$router.push({
              name: 'error'
            });
          })
        }
      })
    }
  },
  created() {
    this.fetchChainedPodcastAndRelated()
  },
  components: {
    PodcastTitle,
    PodcastParentDetail,
    ContentTexte,
    RelatedPodcasts,
    LargeButton
  },

}
</script>

