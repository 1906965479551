<template>
  <section class="about">
      <div class="about__container">
        <img class="about__top-separator" src="./../../assets/images/home/home-cut-shape--raptz-topabout-separator.svg" />
        <img class="about__bubble-left" src="./../../assets/images/home/about-bubble-raptz.png" />
        <router-link v-bind:to="'/raptz'" @click.native="$scrollToTop">
          <img class="about__bubble-right" src="./../../assets/images/home/about-bubble-savoir-plus.png" />
        </router-link>
        <img class="about__josphine" src="./../../assets/images/home/rapporteuz-debout 2josephine.png"/>
      </div>
  </section>
</template>

<script>
export default {
  name:"About",
  methods: {
    scrollToTop() {
              window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>

.about__container {
  position:relative;
  width:100%;
  height:60em;
  margin-top:5em;
  background:url('./../../assets/images/home/banniere-decor.png') no-repeat;
  background-size:cover;
}

.about__main-image {
  position:absolute;
  width:100%;
}

.about__top-separator {
  position:absolute;
  top:-1%;
  right:0;
  width:100%;
}

.about__bubble-left {
  position:absolute;
  max-width:40em;
  top:0;
  left:-3em;
}

.about__bubble-right {
  position:absolute;
  top:13em;
  left:22em;
}

.about__josphine {
  position:absolute;
  bottom:0;
  height:30em;
  right:20vw;
}

@media screen and (min-width: 42em ){
 .about__container {
    height:50em;
    margin-top:10em;
  }

  .about__bubble-left {
    top:auto;
    bottom:0;
    left:-3em;
  }

  .about__bubble-right {
    top:auto;
    bottom:0;
    left:25em;
  }

  .about__josphine {
    top:auto;
    bottom:0;
    right:9vw;
  }
}

@media screen and (min-width: 96em ){

 .about__container {
    height:50em;
  }

  .about__bubble-left {
    position:absolute;
    top:auto;
    bottom:0;
    left:13vw;

  }

  .about__bubble-right {
    position:absolute;
    top:auto;
    bottom:0;
    left:29vw;
  }

  .about__josphine {
    position:absolute;
    bottom:0;
    height:auto;
    right:20vw;
  }
}

</style>