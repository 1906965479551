import Vue from 'vue'
import VueMq from 'vue-mq'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store/store'

import VueGtag from "vue-gtag";
//import VueAnalytics from 'vue-analytics';

Vue.use(VueGtag, {
  config: { id: "G-MTN6F4MQGG" }
});

Vue.config.productionTip = false;

const vue = new Vue({
  //delimiters: ['${', '}'],
  router,
  store,
  render: h => h(App)
})


Vue.use(VueMq, {
  breakpoints: {
    mobile: 800,
    tablet: 1270,
    laptop: Infinity,
  },
  defaultBreakpoint: 'mobile'
})

vue.$mount('#app')
