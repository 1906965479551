<template>
  <div class="ZemixCard">
    <div class="card__zemix">
      <div class="elements__container">
        <img class="zemix" src="./../../assets/images/home/zeMix-title.svg" /> 
        <div class="horaire">Du Lundi au Vendredi de 1h à 17h</div>
        <div class="texte">  ZeMix est la rotation musicale de Radio RapTz.<br><br> Sélection variée, éclectique et sans frontière, ZeMix s'écoute seul ou en bande, à la maison ou boulot, sur ton tel ou sur ton boom  !</div>
      </div>
    </div>
 
  </div>
</template>

<style scoped>

.ZemixCard {
  display:flex;
  flex-direction: column;
  width:100%;
  align-items: center;
  font-family: Chau Philomene One;
  font-style: normal;
  font-weight: normal;
}

.card__zemix {
  max-height:auto;
  background: #FFFFFF;
  border: 4px solid #3E557B;
  border-bottom-width:8px;
  border-right-width:6px;
  border-radius: 10px;
  z-index:50;
}


.elements__container {
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:left;
  padding-top:0;
  padding-left:3.5em;
  padding-right:3.5em;
  z-index:50;
}

.zemix {
  position:absolute;
  top:0px;
  left:50%;
  width:70%;
  transform:translate(-50%,-50%);
  height:auto;

}

.horaire {
  text-align:left;
  padding-top:25%;
  font-family: Noto Sans;
  font-size: 1.5em;
  line-height: 150%;
  margin-bottom:5%;
}

.texte {
  text-align:left;
  font-size: 2.2em;
  margin-bottom:10%;
}

.icone {
  width:18%;
  height:auto;
  z-index:500;
}

.decouvrir {
  text-align:left;
  display:flex;
  flex-direction: row;
  align-items:center;
  padding-bottom:2vh;
}

.decouvrir__texte {
  margin-left:5%;
  font-size: 1.8em;
  line-height: 2.5em;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #F42740;
}

@media screen and (min-width: 800px ) {
  .ZemixCard {
    display:flex;
    align-items: left;
    flex-direction: column;
    font-family: Chau Philomene One;
    font-style: normal;
    font-weight: normal;
    width:36.2em;
  }

  .card__zemix {
    width:36.2em;
  }

}

</style>

<script>
export default {
  name: 'ZemixCard',
}
</script>

