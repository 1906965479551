<template>
<div class="page">
  <h1>La page que vous avez demandé n'existe pas !</h1>
  <br>
  <p>Revenir sur notre page d'<router-link v-bind:to="'/'"><span class="color__primary">Accueil</span></router-link>
  </p>
  <br>
  <router-link v-bind:to="'/'"><img src="./../assets/images/header/logo-banniere.svg" class="logo background"></router-link>
</div>
</template>


<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.page {
  min-height:100vh;
  padding-top:10em;
  font-size:1.5em;
  text-align: center;
}

.background {
  background-color:#3A4D6C;
  padding:1em;
}
</style>