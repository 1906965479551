<template>
  <div class="card__container" :style="style">
    <div class="card__image-container">
      <img class="card__image" :src="pictureURL" />
      <img class="card__separator" v-if="backgroundColor == '#F42740'" src="./../../assets/images/home/cards/home-maincard-cut--right-red.svg" />
      <img class="card__separator" v-else src="./../../assets/images/home/cards/home-maincard-cut--right-dark.svg" />

    </div>
    <div class="box-card__text">
      <h2 class="box-card__titre">{{titre}}</h2>
      <div class="box-card__courte-description">{{courteDescription}}</div>
      <div class="icon-and-text-row-container">
        <p>En savoir plus</p>
        <img src="./../../assets/images/icone/icon-read-more-red.svg">
      </div>
    </div>
  </div>
</template>

<style scoped>

.card__container {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90em;
    border-radius:1em;
    flex-wrap : nowrap;
}

.card__image-container {
    flex:2;
    height:100%;
    border:none;
    position:relative;
}

.card__image {
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 1em;
}

.card__separator {
    position:absolute;
    height:100%;
    right:-1px;
    top:0;
}

.box-card__text {
    flex:1;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding:1em 1em 1em 1.2em;
    color:white;

}

.box-card__titre {
   font-size:3.8em;
   letter-spacing: 0.03em;
   font-style: normal;
   font-weight: normal;
   font-family: Chau Philomene One;
}



.icon-and-text-row-container {
    margin:2em 1em;
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
    color:#F42740;
    text-transform: uppercase;
    font-size:1.4em;
    letter-spacing: 0.03em;
    font-family: Chau Philomene One;
}

  .icon-and-text-row-container img {
    margin-left:1em;
  }

</style>

<script>
export default {
  name: 'HorizontalArticleCard',
  props:{
    backgroundColor: String,
    imageNom: String,
    titre: String,
    courteDescription: String
  },
  computed: {
    getDay: function(){
      const dictDay = {
        1: "Lundi",
        2: "Mardi",
        3: "Mercredi",
        4: "Jeudi",
        5: "Vendredi",
        6: "Samedi",
        7 :"Dimanche"
      }; 
      return (day) => dictDay[day] ;
    },
    style(){
      return 'background-color:' + this.backgroundColor;
    },
    pictureURL(){
      if (this.imageNom.includes('http')){
        return this.imageNom;
      } else {
        return require(`@/assets/images/temp/${this.imageNom}`);
      }
    },

    /**
     * We check on mounted if all starting hours of the show are the same
     */

  },
}
</script>
