<template>
  <div 
    v-if="isSearchToggle" 
    class="modal-overlay">
    <div class="search-modal">
        <input     
            @focusout="handleFocusOut"
            tabindex="0"
            type="text" v-on:keyup.enter="submitSearchString" v-model="searchString" placeholder="articles, podcasts, artistes, auteurs..."/>
          <button class="" v-on:click.prevent="submitSearchString">
            <img class="search-logo" src="./../../assets/images/icone/icon-search-black.svg">
          </button>
          <button class="search-logo" v-on:click.prevent="toggleSearchModal">
            X
          </button>
    </div>
  </div>
</template>

<script>

export default {
  name:"SearchModal",
  data() {
    return {
      searchString :"",
      }
    },
  computed: {
    isSearchToggle() {
        if (this.$store.state.isSearchModalOpen) {
            return true;
        }
        return false;
    }
  },
  methods: {
    toggleSearchModal() {
      console.info('Toggle has been clicked');
      this.$store.commit('toggleSearchModal');
    },

    submitSearchString() {
      if(this.$route.fullPath != '/search'){
        this.$store.commit('toggleSearchModal');
        this.$store.commit('setSearchValue', this.searchString);
        return this.$router.push({
          name: 'search-results'
        });
      } else {
        this.$store.commit('toggleSearchModal');
        this.$store.commit('setSearchValue', this.searchString);
      }
    },
    
    handleFocus() {
        console.log('click on focus');
    },
    handleFocusOut() {
        this.$store.commit('toggleSearchModal');
    }
  }
}
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,0.9);
    z-index:300;
  }

  .search-modal {
    text-align: center;
    background-color: white;
    width: 500px;
    height:2rem;
    margin-top: 10%;
    display:flex;
    flex-direction:row;
    border-radius: 20px;
  }

  .search-logo {
    height:2rem;
    border-radius: 20px;
  }

  button {
    height:2rem;
    border-radius: 20px;
  }

  input {
    height:2rem;
    padding: 4px 15px;
    color: rgba(0,0,0,.70);
    border-top: 1px solid rgba(0,0,0,.12);
    border-bottom:1px solid rgba(0,0,0,.12);
    transition: .15s all ease-in-out;
    background: white;
    width: 100%;
    border-radius: 20px 0 0 20px;
  }

  input:focus {
    outline: none;
    transform: scale(1.05);

  }
  input::-webkit-input-placeholder {
    font-size: 24px;
    color: rgba(0,0,0,.50);
    font-weight: 100;
  }
</style>