<template>
  <div class="card__container" :style="style">
    <div class="card__image-container">
      <img class="card__image" :src="pictureURL" />
      <img class="card__separator" v-if="color == '#F42740'" src="./../../assets/images/home/cards/home-maincard-cut--right-red.svg" />
      <img class="card__separator" v-else src="./../../assets/images/home/cards/home-maincard-cut--right-dark.svg" />

    </div>
    <div class="box-card__text">
      <h3>{{sliceTexte(titre)}}</h3>
      <p class="box-card__courteDescription">{{sliceDescription}}</p>
      <div class="main__introduction horizontal__introduction">L'émission à écouter</div>
    </div>
  </div>
</template>

<style scoped>

  .card__container {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    position:relative;
    max-width: 96.4em;
    border-radius:1em;
    flex-wrap : nowrap;
    font-family: Chau Philomene One;
  }

  .card__image-container {
    flex:3;
    height:100%;
    border:none;
    position:relative;
  }

  .card__image {
    height:100%;
    width:100%;
    object-fit: fill;
    border-radius: 1em;
  }

  .card__separator {
    position:absolute;
    height:100%;
    right:-1px;
    top:0;
  }

  .box-card__text {
    flex:1;
    text-align:left;
    padding:1em 1em 1em 1.2em;
    font-size:1.5em;
    color:white;
    position:relative;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .box-card__courteDescription {
    font-family: "Noto Sans", sans-serif;
    margin-top:1em;
    }

  .icon-and-text-row-container {
    margin:1em 1em;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    color:white;
  }

  .icon-and-text-row-container img {
    margin-right:2em;
  }
  
  .horizontal__introduction {
    font-size: 1.5em;
    width:auto;
    text-transform: uppercase ;
    text-decoration:underline;
  } 

</style>

<script>
export default {
  name: 'HorizontalCard',
  props:{
    color: String,
    imageNom: String,
    titre: String,
    courteDescription: String
  },
  methods: {
    sliceTexte:function(texte) {
      let threshold = 30 ;
      if(texte != null) {
        if(texte.length > threshold) {
          return texte.slice(0,threshold) + '...';
        } else {
          return texte;
        }
      }
    },
  },
  computed: {
    style(){
      return 'background-color:' + this.color;
    },
    pictureURL(){
      if (this.imageNom.includes('http')){
        return this.imageNom;
      } else {
        return require(`@/assets/images/temp/${this.imageNom}`);
      }
    },
    sliceDescription(){
      let threshold = 100 ;
      if(this.courteDescription != null) {
        if(this.courteDescription.length > threshold) {
         return this.courteDescription.slice(0,threshold) + '...';
        }
      }
      return this.courteDescription;
   },
  
  }
}
</script>
